import openClient from 'socket.io-client'
import { arraysContainSameElements, resetCardGroups } from './funcs'
import { logger } from './logger'
import { MyGameInterface, Player } from './store/game'
import { store } from './store/index'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

export const socket = openClient(store.getState().info.serverURL, {
  reconnection: true
})
export const joinGame = (room: string, name: string): void => {
  const { setLoading } = store.getActions().info
  const { updatePlayersInRoom, setMe, addHand } = store.getActions().game
  const { playersInRoom } = store.getState().game.myGame
  //these are what we are sending to the server, first is key, following are all args
  socket.emit('join-game', room, name, playersInRoom)

  //this is what happens get when server emits 'joined', second argument is a function that outlines the information
  //we are getting back from the server
  socket.on(
    'joined',
    (room: string, name: string, clientID: string, playerStrings: string[]) => {
      let players: Player[] = playerStrings.map((ps) => JSON.parse(ps))
      updatePlayersInRoom(players.map((p, i) => ({ ...p, position: i })))
      setLoading(false)
      addHand({ name, clientID })
    }
  )
  socket.on(
    'cannot-join-game',
    (message: string, room: string, playersStr: string) => {
      //allow player to re-enter if they are one of the previously joined players...comparing by name, otherwise say there cannot be more than 4 players in the room
      let players: Player[] = JSON.parse(playersStr)
      let matchedPlayer = players.find(
        (player) => player.name === store.getState().game.me.name
      )
      if (matchedPlayer) {
        confirmAlert({
          title: 'Rejoin?',
          message: 'Do you want to rejoin this game as ' + matchedPlayer.name,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                window.location.href = `${window.location.origin}/game/${room}/${matchedPlayer?.clientID}`
              }
            },
            {
              label: 'No',
              onClick: () => {
                window.location.href = window.location.origin
              }
            }
          ]
        })
      } else window.alert(message)
    }
  )
  socket.on(
    'assigned-clientID-and-team',
    (clientID: string, team: 'red' | 'blue' | '') => {
      const { me } = store.getState().game
      setMe({ ...me, room, clientID, team })
    }
  )
}

export const refreshSocketAndStore = () => {
  store.dispatch.reset()
  socket.disconnect()
  socket.connect()
}

export const stopListeningForEvent = (eventName?: string) => {
  if (eventName) socket.off(eventName)
  else socket.disconnect()
}

export const sendDiscardSound = () => {
  const room = store.getState().game.me.room
  socket.emit('send-discard-sound', room)
}

export const sendShuffleSound = () => {
  const room = store.getState().game.me.room
  socket.emit('send-shuffle-sound', room)
}

export const receiveSounds = () => {
  socket.on('sent-discard-sound', () => {
    const discardSoundRef = store.getState().info.discardSoundRef
    discardSoundRef && discardSoundRef.click()
  })
  socket.on('sent-shuffle-sound', () => {
    const shuffleSoundRef = store.getState().info.shuffleSoundRef
    shuffleSoundRef && shuffleSoundRef.click()
  })
}

export const sendGameState = ({
  endTurn = true,
  endHand = false,
  restart = false,
  nextHand = false,
  gameState
}: {
  endTurn?: boolean
  endHand?: boolean
  restart?: boolean
  nextHand?: boolean
  gameState?: MyGameInterface
}) => {
  const myGame = gameState || store.getState().game.myGame
  const { room } = store.getState().game.me

  //end hand
  if (endHand) {
    myGame.status = 'finished'
  }

  //restart game
  if (nextHand) {
    resetCardGroups(myGame)
    myGame.status = 'started'
    myGame.showHandClientID = ''
    myGame.teamInfo = {
      blue: { score: myGame.teamInfo['blue'].score, goneDown: false },
      red: { score: myGame.teamInfo['red'].score, goneDown: false }
    }
  } else if (restart) {
    resetCardGroups(myGame)
    myGame.status = 'started'
    myGame.showHandClientID = ''
    myGame.teamInfo = {
      blue: { score: 0, goneDown: false },
      red: { score: 0, goneDown: false }
    }
  }

  //increment turn
  if (endTurn) {
    let newPosition: number
    if (myGame.activePlayer.position !== undefined) {
      if (myGame.activePlayer.position >= myGame.playersInRoom.length - 1) {
        newPosition = 0
      } else {
        newPosition = myGame.activePlayer.position + 1
      }
    } else throw new Error('position is undefined')

    myGame.activePlayer = myGame.playersInRoom[newPosition]
  }

  const reset = nextHand || restart || false

  socket.emit('update-game-state', room, JSON.stringify(myGame), reset)
}

export const attemptToRejoinGame = (room: string, clientID: string) => {
  const { setRoom } = store.getActions().info
  const { setMe } = store.getActions().game
  setRoom(room)
  setMe({ clientID, room, name: '', team: '' })
  socket.emit('rejoin', room, clientID)
}

export const listenForReconnection = (room: string, clientID: string) => {
  socket.on('reconnecting', () => {
    logger('reconnecting')
  })
  socket.on('reconnect', () => {
    logger('reconnected')
    window.location.reload()
  })
}

export const receiveGameState = () => {
  socket.on('disconnect', () => {
    console.log('socket disconnecting')
  })
  socket.on(
    'rejoined',
    (rejoiningPlayerStr: string, newGameStateStr: string) => {
      let rejoiningPlayer: Player = JSON.parse(rejoiningPlayerStr)
      let newGameState: MyGameInterface = JSON.parse(newGameStateStr)
      const me = store.getState().game.me

      const { updateGameState, setMe } = store.getActions().game
      // if (me.clientID !== rejoiningPlayer.clientID) {
      //   newGameState.groups[`hand-${me.clientID}`] =
      //     myGame.groups[`hand-${me.clientID}`]
      // }

      if (me.clientID === rejoiningPlayer.clientID) {
        setMe({
          ...me,
          ...rejoiningPlayer,
          isMyTurn: newGameState.activePlayer.clientID === me.clientID
        })
        updateGameState(newGameState)
      }

      logger(`${rejoiningPlayer.name} rejoined`)
    }
  )

  //received from sendGameState
  socket.on('updated-game-state', (newGameStateStr: string, reset: boolean) => {
    let newGameState: MyGameInterface = JSON.parse(newGameStateStr)
    const { me, myGame } = store.getState().game
    const { updateGameState, setMe } = store.getActions().game
    updateGameState(newGameState)
    if (!me.isMyTurn && !reset) {
      newGameState.groups[`hand-${me.clientID}`] =
        myGame.groups[`hand-${me.clientID}`]
    }
    setMe({ isMyTurn: newGameState.activePlayer.clientID === me.clientID })
  })

  socket.on('popped-game-history', (newGameStateStr: string) => {
    let newGameState: MyGameInterface = JSON.parse(newGameStateStr)
    const { me, myGame } = store.getState().game
    const { updateGameState, setMe } = store.getActions().game
    // if (!me.isMyTurn) {
    //   newGameState.groups[`hand-${me.clientID}`] =
    //     myGame.groups[`hand-${me.clientID}`]
    // }
    if (
      arraysContainSameElements(
        myGame.groups[`hand-${me.clientID}`].cardIDs,
        newGameState.groups[`hand-${me.clientID}`].cardIDs
      )
    ) {
      newGameState.groups[`hand-${me.clientID}`].cardIDs =
        myGame.groups[`hand-${me.clientID}`].cardIDs
    }
    updateGameState(newGameState)
    setMe({ isMyTurn: newGameState.activePlayer.clientID === me.clientID })
  })
  socket.on('disconnected', (disconnectedPlayerStr: string) => {
    let disconnectedPlayer: Player = JSON.parse(disconnectedPlayerStr)
    if (disconnectedPlayer?.name)
      logger(`${disconnectedPlayer.name} is disconnected`)
  })
}
export const receiveInitialGameState = () => {
  socket.on('populated-initial-history', (newGameStateStr: string) => {
    let newGameState: MyGameInterface = JSON.parse(newGameStateStr)
    const { updateGameState, setMe } = store.getActions().game
    const me = store.getState().game.me
    updateGameState(newGameState)

    const updatedPosition = newGameState.playersInRoom.find(
      (player) => player.clientID === me.clientID
    )?.position

    const myUpdatedTeam =
      newGameState.playersInRoom.find((p) => p.clientID === me.clientID)
        ?.team || ''

    setMe({
      isMyTurn: newGameState.activePlayer.clientID === me.clientID,
      team: myUpdatedTeam,
      position: updatedPosition || me.position
    })
  })
}

export type DebugStatus = 'loading' | 'success' | 'error' | ''

export const sendDebugEmail = (
  image: string,
  text: string,
  set: React.Dispatch<React.SetStateAction<DebugStatus>>
) => {
  const me = store.getState().game.me
  const room = window.location.pathname.split('/')[2] || me.room
  socket.emit('send-debug-email', room, JSON.stringify(me), image, text, 10)

  if (!socket.hasListeners('sent-debug-email')) {
    //if this listener is already registered, register it
    socket.on('sent-debug-email', (message: 'success' | 'error') => {
      console.log('sent debug email')
      set(message)
    })
  }
}

export const populateInitialHistory = (options?: {
  gameState?: MyGameInterface
  status?: 'started' | 'waiting'
}) => {
  const myGame = options?.gameState || store.getState().game.myGame
  if (options?.status) myGame.status = options.status
  const { room } = store.getState().game.me
  myGame.playersInRoom.forEach((player: Player) => {
    if (!(`hand-${player.clientID}` in myGame.groups)) {
      myGame.groups['hand-' + player.clientID] = {
        title: `${player.name}'s Hand`,
        id: 'hand-' + player.clientID,
        cardIDs: []
      }
    }
  })

  let playerStrings = myGame.playersInRoom.map((player) =>
    JSON.stringify(player)
  )

  socket.emit(
    'populate-initial-history',
    room,
    JSON.stringify(myGame),
    playerStrings
  )
}

export const popFromGameHistory = () => {
  const { room } = store.getState().game.me

  socket.emit('pop-game-history', room)
}
