import styled from '@emotion/styled'
import cryptoRandomString from 'crypto-random-string'
import React, { useState } from 'react'
import { isSafari, isTablet } from 'react-device-detect'
import { RiLoginCircleLine } from 'react-icons/ri'
import { MdHome } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { Accentuated } from '../1-reusable/Accentuated'
import ReactTooltip from 'react-tooltip'
import { BuyMeACoffeeButton } from '../1-reusable/BuyMeACoffeeButton'
import { ClickableAnchor } from '../1-reusable/ClickableAnchor'
import { Column, MediaRow } from '../1-reusable/ColumnAndRow'
import { Header } from '../1-reusable/Header'
import { Input } from '../1-reusable/Input'
import { MyBanner } from '../1-reusable/MyBanner'
import { HomeButton, MyButton } from '../1-reusable/MyButton'
import {
  NormalFont,
  NormalFontH2Spaced,
  NormalListItemFontSpaced,
  NormalParagraphFontSpaced,
  UnorderedListContainer
} from '../1-reusable/NormalFont'
import { Redirect } from '../1-reusable/Redirect'
import { colors as c, colors } from '../colors'
import { typedActions } from '../store/index'

export const Home: React.FC = (props) => {
  const { setMe } = typedActions((actions) => actions.game)
  const [roomCode, setRoomCode] = useState('')
  const [name, setName] = useState('')
  const history = useHistory()
  const [addToHomeScreenOpen, setAddToHomeScreenOpen] = useState(false)

  const handleNewGame = () => {
    if (!name) {
      window.alert('Please enter a name to start a game')
      return
    }
    setMe({ name })
    history.push(`/lobby/${cryptoRandomString({ length: 5, type: 'numeric' })}`)
  }
  const handleJoinGame = () => {
    if (!name) {
      window.alert('Please enter a name before you join a game')
      return
    }
    //see if you are trying to rejoin last game
    const lastGame = window.localStorage.getItem('lastGamePath')
    if (lastGame?.split('/')[2] === roomCode) {
      history.push(lastGame)
      return
    }

    setMe({ name })
    history.push(`/lobby/${roomCode}`, { fromJoinGame: true })
  }

  if (window.location.origin.includes('beta')) {
    return (
      <PageContainer>
        <Redirect>
          <NormalFont style={{ color: colors.lightRed }}>
            Canasta.io is now live. Before continuing to the site, please
            consider supporting us!
          </NormalFont>
          <BuyMeACoffeeButton />
        </Redirect>
        <ClickableAnchor href={'https://canasta.io'}>
          <HomeButton style={{ margin: 40 }}>Visit Canasta.io</HomeButton>
        </ClickableAnchor>
      </PageContainer>
    )
  }
  return (
    <PageContainer>
      <MyButton
        data-tip="Rejoin Last Game"
        style={{
          position: 'absolute',
          top: 5,
          left: 15,
          margin: 10,
          color: c.gold,
          backgroundColor: c.darkBackground,
          cursor: 'pointer',
          borderRadius: 100,
          display: 'flex'
        }}
        size="tiny"
        onClick={() => {
          const path = window.localStorage.getItem('lastGamePath')
          if (path) history.push(path)
          else window.alert('No recent games on this device/browser.')
        }}
      >
        <ReactTooltip />
        <RiLoginCircleLine style={{ padding: 4, fontSize: 30 }} />
      </MyButton>
      <MyBanner>
        <NormalFont style={{ color: colors.lightRed }}>
          Please help us keep the site running!
        </NormalFont>
        <BuyMeACoffeeButton />
      </MyBanner>
      <Header style={{ color: 'white' }}>Canasta</Header>
      {isTablet && isSafari && (
        <MyButton
          style={{ color: c.gold, backgroundColor: c.darkBackground }}
          onClick={() => setAddToHomeScreenOpen(true)}
          size="tiny"
        >
          <MdHome /> Add App to Home Screen
        </MyButton>
      )}
      <ColumnContainer>
        <Column
          style={{
            alignSelf: 'center'
          }}
        >
          <NormalFont
            style={{
              fontWeight: 'bold',
              fontSize: 20,
              paddingBottom: '4px',
              textAlign: 'center'
            }}
          >
            Welcome to canasta.io.
          </NormalFont>
          <NormalFont
            style={{
              fontWeight: 'lighter',
              fontSize: 18,
              lineHeight: 1.5,
              textAlign: 'left'
            }}
          >
            To start a game, enter your player name and click the{' '}
            <Accentuated>New Game</Accentuated> button. You will receive a game
            code that you can share. To join an existing game, enter your player
            name and the game code your friend shared with you. Then, click the{' '}
            <Accentuated>Join Game</Accentuated> button.
          </NormalFont>
        </Column>
        <MediaRow>
          <Input
            id={'choosename'}
            label="Enter Your Player Name"
            set={setName}
          />
        </MediaRow>
        <MediaRow style={{ justifyContent: 'normal' }}>
          <Input id="entercode" label="Enter Game Code" set={setRoomCode} />
          <MyButton
            style={{ marginLeft: '8px', flex: 1 }}
            size="small"
            type="button"
            onClick={() => handleJoinGame()}
          >
            Join Game
          </MyButton>
        </MediaRow>
        <MyButton type="button" onClick={() => handleNewGame()}>
          New Game
        </MyButton>
        <br />
        <NormalFont
          style={{ fontSize: 28, fontWeight: 'bolder', textAlign: 'center' }}
          color={c.lightBlue}
        >
          Instructions
        </NormalFont>
        <NormalFontH2Spaced>Overview</NormalFontH2Spaced>
        <NormalParagraphFontSpaced>
          The easiest way to think of this app is to understand that its purpose
          is very limited: it is simply to provide a shuffled deck and a board
          for four friends to play on – and not much else. The app does not know
          how to play the game of canasta; you can only use it to play with
          three other players in a game you organize; you cannot play “against
          the computer” on the app, nor does it allow players you do not invite
          to join your game.
        </NormalParagraphFontSpaced>
        <NormalFontH2Spaced>Starting a Game</NormalFontH2Spaced>
        <NormalParagraphFontSpaced>
          The game starter goes to the Canasta.io website, enters his/her game
          name, and clicks on “New Game”. The app will then display a numerical
          game code. The game starter then shares the code with the other three
          players. They each go to the Canasta.io website, enter their game
          names and the code, and click on “Join Game”. The app partners players
          number 1 and 3 and players number 2 and 4. That partnering can be
          changed by the game starter to form the desired teams before the game
          begins by dragging the names into the desired order. The game starter
          then clicks on “Start Game”. Your game board and your hand will
          appear. The two teams are designated red or blue. Your partner’s name
          will appear in one of those two colors and that signifies which board
          you play on. You can rearrange the cards in your hand by dragging them
          into any order you prefer; or you can click on “sort cards” and the
          app will automatically sort your hand for you in a predetermined
          order, with wildcards to the left, threes to the right, and the
          remaining cards in numerical order.
        </NormalParagraphFontSpaced>
        <NormalFontH2Spaced>Playing on the Board</NormalFontH2Spaced>
        <NormalParagraphFontSpaced>
          Cards are moved by clicking and dragging with a mouse or by holding
          and dragging on an iPad. To draw a card, click on the “draw” button,
          which will draw a card for you and deposit it on the right side of
          your hand. (You can then click on “sort cards” again to put that card
          in the proper place in your hand). If you have threes, place them on
          your board and then draw another card or cards. The app will not
          automatically draw new cards for you for threes. To go down or to add
          to started melds, drag the cards to the correct boxes on your board –
          either the blue board or the red board depending on which team you are
          on. As you drag a card over the boxes, the boxes will change color
          from black to either blue or red to help you see when to stop and drop
          the card. The app does not recognize which cards are supposed to go on
          which boxes, so if you drop one on the wrong box, just click “undo”
          and try again. When you discard, the turn automatically goes to the
          next person.
        </NormalParagraphFontSpaced>
        <NormalFontH2Spaced>Rules of Play</NormalFontH2Spaced>
        <NormalParagraphFontSpaced>
          The app is not programmed with any rules of play. When you start a
          game, you don’t need to customize anything. The app does not govern
          how you play; or police you when you break the rules of play. Your
          fellow players will police you just the way they would if you were
          playing in person – if you try to pick the deck without putting down
          two cards first, your fellow players will stop you. If you try to go
          down without a clean meld, or enough points, your fellow players will
          point that out and you will need to add ten points next time you go
          down. The app will not stop you from going down without a clean meld
          or with too few points. You and your fellow players need to police the
          rules of play yourselves; the app will not. It will allow you to do
          whatever you want on the board regardless of the rules of play. This
          means that a group of players can decide on their own rules – the app
          will allow you to play by whatever rules you choose: wipe-out or not;
          shorties or not: assists or not: whatever splashes you want (with
          whatever point values you want to assign to the various splashes);
          etc. Just as if you were playing in person.
        </NormalParagraphFontSpaced>
        <NormalFontH2Spaced>
          Differences from other canasta apps
        </NormalFontH2Spaced>
        <NormalParagraphFontSpaced>
          The fact that this app does not govern the rules of play is the
          biggest difference from other apps, but there are others:
          <UnorderedListContainer>
            <NormalListItemFontSpaced>
              This app uses the best shuffling algorithm available; there will
              be no repeat hands
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              There is no play clock – if someone is taking too much time, feel
              free to gently encourage them to play more quickly or don’t.
              Whatever you would have done if you were playing in person
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              The playing boards are color-coded; if your team’s names appear in
              red; you play on the red board, which is on the left hand side; if
              your team’s names appear in blue you play on the blue board on the
              right hand side (the app will not let you put a card on the wrong
              board; this means that there are times when you will be playing on
              the left hand board
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              When you add the sixth card to a canasta, the meld will pulse
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              The “undo” button works for everything – but only the person whose
              turn it is can click on the “undo” button. If you drop a card on
              the wrong place on your playing board, hit undo. If you go down by
              mistake, keep hitting undo as many times as necessary to get all
              of the cards back in your hand. If you discard a card in error
              (you meant to put it on a meld and accidentally discarded it, for
              example), it will automatically become the next person’s turn. But
              that person can click on their “undo” button, and the turn will
              revert to you
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              To pick the pack, put your two cards down on your board, and then
              click on the “pick the pack” button
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              To look at the previous card thrown, click on the “peek previous
              discard” button
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              If you splash, click on the “show my hand” button{' '}
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              The scoring is not automatic - you must score your own hands as
              described below
            </NormalListItemFontSpaced>
          </UnorderedListContainer>
        </NormalParagraphFontSpaced>
        <NormalFontH2Spaced>Scoring</NormalFontH2Spaced>
        <NormalParagraphFontSpaced>
          At the end of a hand, whether because the last card is drawn, or
          someone goes out, or someone splashes, you calculate the scores for
          that hand as follows:
          <UnorderedListContainer>
            <NormalListItemFontSpaced>
              Open the scoring mechanism by clicking on the gear button on the
              upper left
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              Click on the “calculator” button in the drop down menu and use it
              to calculate each team’s base score (threes, closed canastas,
              special canastas, and going out; penalty points for threes,
              sevens, aces, and incomplete special canastas); once you calculate
              the base for one team, click on the appropriate “add to” button on
              the side of the calculator and the amount will be added to that
              team’s score (the scores appear in the upper right hand and left
              hand of the board)
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              To add the points total for each team to the base, click on the
              “Blue points” and the “Red points” buttons in the dropdown menu –
              the app will then automatically count the points on the board
              minus points in both players’ hands for each team, and add that
              amount to the team’s score (if a team did not complete a single
              canasta, all of that team’s points on the board and in both
              players’ hands will be negative)
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              If one team has splashed, and you are playing wipe-out, do not
              click on either the red or the blue points buttons. Instead just
              use the calculator to give the splashing team its points
            </NormalListItemFontSpaced>
            <NormalListItemFontSpaced>
              If you are not playing wipe out, use the calculator to give the
              splashing team its points; use the calculator to give the other
              team its base score, and then the points button for that team
            </NormalListItemFontSpaced>
          </UnorderedListContainer>
          The app will cumulate the scores from hand to hand, until the “new
          game” button is clicked.
        </NormalParagraphFontSpaced>
        <NormalParagraphFontSpaced>
          <NormalFont
            style={{ fontWeight: 800, display: 'inline', color: c.lightBlue }}
          >
            To start the next hand
          </NormalFont>
          Click on the gear button in the upper left and click on “next hand”.
        </NormalParagraphFontSpaced>
        <NormalParagraphFontSpaced>
          <NormalFont
            style={{ fontWeight: 800, display: 'inline', color: c.lightBlue }}
          >
            To start a new game
          </NormalFont>
          Click on the gear button in the upper left and click on “new game”.
        </NormalParagraphFontSpaced>
        <NormalParagraphFontSpaced>
          <NormalFont
            style={{ fontWeight: 800, display: 'inline', color: c.lightBlue }}
          >
            To close the dropdown menu
          </NormalFont>
          Click on the gear button again
        </NormalParagraphFontSpaced>
        <NormalFontH2Spaced>Internet Problems</NormalFontH2Spaced>{' '}
        <NormalParagraphFontSpaced>
          If you lose your internet connection, or your game board freezes,
          start by trying the refresh button (the circular arrow) in the address
          bar of your browser on your computer or iPad. If this doesn’t work,
          close the Canasta.io window. Then reopen the Canasta.io window and
          simply click on the Rejoin Last Game button in the upper left hand
          corner of the home page. Do not put your name or game code in again,
          and do not click on the join game button! If your group wants to exit
          a game and restart it at a later time for any reason, simply make a
          note of the score when you ended. Start a new game using the same
          teams and add the previous score manually using the calculator to the
          scores at the end of the first hand of your restarted game.
        </NormalParagraphFontSpaced>
        <NormalFont
          style={{ fontSize: 28, fontWeight: 'bolder' }}
          color={c.lightBlue}
        >
          Enjoy Canasta.io and let your friends know about it!
        </NormalFont>
      </ColumnContainer>
      {/* Unable to reload when using shortcut */}
      {/* <AddToHomeScreenDialog
        open={addToHomeScreenOpen}
        setOpen={setAddToHomeScreenOpen}
      /> */}
    </PageContainer>
  )
}
const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ColumnContainer = styled.div`
  display: flex;
  width: 68vw;
  border-radius: 15px;
  flex-flow: column nowrap;
  align-items: stretch;
  > * {
    margin: 1rem;
  }
`
