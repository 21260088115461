import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Game } from './2-pages/Game'
import { Home } from './2-pages/Home'
import { Lobby } from './2-pages/Lobby'

export const Routes: React.FC = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path={['/', '/home']} component={Home} />
        <Route exact path="/lobby/:room" component={Lobby} />
        <Route exact path="/game/:room/:clientID" component={Game} />
        <Route path="*" component={Home} />
      </Switch>
    </div>
  )
}
