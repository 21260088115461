import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { store, typedState } from '../store/index'
import { Card } from './Card'
import isEqual from 'lodash.isequal'
import { cardInterfaceMap } from '../store/cardInterfaceMap'
export const Hand: React.FC = (props) => {
  const me = typedState((state) => state.game.me)
  const myCardIDs = typedState(
    (state) => state.game.myGame.groups[`hand-${me.clientID}`].cardIDs,
    isEqual
  )
  const cards = cardInterfaceMap
  useEffect(() => {
    let handContainer = document.getElementById('hand-container')
    let supposedLength =
      store.getState().game.myGame.groups?.[`hand-${me.clientID}`]?.cardIDs
        .length || 0
    let actualLength = handContainer?.childElementCount || 0

    //if they are not equal or will becomes equal next render, automatically reload page
    if (Math.abs(actualLength - supposedLength) > 1) window.location.reload()
  }, [myCardIDs])

  return (
    <Droppable droppableId={`hand-${me.clientID}`} direction={'horizontal'}>
      {(provided) => {
        return (
          <Container
            {...provided.droppableProps}
            id={'hand-container'}
            ref={provided.innerRef}
          >
            {myCardIDs.map((id, idx) => {
              return (
                <Draggable key={id} draggableId={id} index={idx}>
                  {(provided, snapshot) => {
                    return (
                      <CardWrapper
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                        style={
                          !snapshot.isDropAnimating ||
                          snapshot.draggingOver === 'hand'
                            ? provided.draggableProps.style
                            : {
                                ...provided.draggableProps.style,
                                transitionDuration: '.01s'
                              }
                        }
                      >
                        {!snapshot.isDropAnimating && (
                          <Card size="tiny" card={cards[id]} hand />
                        )}
                      </CardWrapper>
                    )
                  }}
                </Draggable>
              )
            })}

            {provided.placeholder}
          </Container>
        )
      }}
    </Droppable>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  max-width: 90vw;
  min-width: 400px;
  .cardWrapper:last-child {
    overflow: visible;
  }
  .cardWrapper:hover {
  }
  .cardWrapper {
  }
`

const CardWrapper = styled.div<{ isDragging?: boolean }>`
  padding-top: 12px;
  padding-bottom: 4px;
  overflow: ${({ isDragging }) => (isDragging ? 'visible' : 'hidden')};
  &:hover {
    padding-left: 2px;
    padding-right: 2px;
  }
  &:last-child {
    overflow: visible;
  }
`
