import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { isChrome, isMobile } from 'react-device-detect'
import { Sounds } from './1-reusable/Sounds'
import './App.css'
import { Routes } from './Routes'

function App() {
  const discardSoundRef = null
  const shuffleSoundRef = null
  useEffect(() => {
    setTimeout(() => {
      const browserAlert = window.localStorage.getItem('browserAlert')
      !isChrome &&
        !isMobile &&
        !browserAlert &&
        window.alert('For better experience use Google Chrome.')
      window.localStorage.setItem('browserAlert', 'true')
    }, 1000)
  }, [])
  return (
    <div className="App">
      <AppContainer>
        <Routes />
        <Sounds discardRef={discardSoundRef} shuffleRef={shuffleSoundRef} />
      </AppContainer>
    </div>
  )
}

const AppContainer = styled.header`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  > span {
    font-size: 75px;
    padding: 2rem;
  }
`

export default App
