import { CardInterfaceMap } from './game'

export const cardInterfaceMap: CardInterfaceMap = {
  'Two-diamonds': {
    rank: { shortName: '2', longName: 'Two' },
    suit: { name: 'diamonds' },
    id: 'Two-diamonds'
  },
  'Five-diamonds': {
    rank: { shortName: '5', longName: 'Five' },
    suit: { name: 'diamonds' },
    id: 'Five-diamonds'
  },
  'Queen-diamonds': {
    rank: { shortName: 'Q', longName: 'Queen' },
    suit: { name: 'diamonds' },
    id: 'Queen-diamonds'
  },
  'Six-diamonds': {
    rank: { shortName: '6', longName: 'Six' },
    suit: { name: 'diamonds' },
    id: 'Six-diamonds'
  },
  'Jack-clubs': {
    rank: { shortName: 'J', longName: 'Jack' },
    suit: { name: 'clubs' },
    id: 'Jack-clubs'
  },
  'Jack-spades': {
    rank: { shortName: 'J', longName: 'Jack' },
    suit: { name: 'spades' },
    id: 'Jack-spades'
  },
  'Six-clubs': {
    rank: { shortName: '6', longName: 'Six' },
    suit: { name: 'clubs' },
    id: 'Six-clubs'
  },
  'Jack-spades-2': {
    rank: { shortName: 'J', longName: 'Jack' },
    suit: { name: 'spades' },
    id: 'Jack-spades-2'
  },
  'Nine-hearts': {
    rank: { shortName: '9', longName: 'Nine' },
    suit: { name: 'hearts' },
    id: 'Nine-hearts'
  },
  'Four-hearts': {
    rank: { shortName: '4', longName: 'Four' },
    suit: { name: 'hearts' },
    id: 'Four-hearts'
  },
  'Queen-diamonds-2': {
    rank: { shortName: 'Q', longName: 'Queen' },
    suit: { name: 'diamonds' },
    id: 'Queen-diamonds-2'
  },
  'Three-hearts': {
    rank: { shortName: '3', longName: 'Three' },
    suit: { name: 'hearts' },
    id: 'Three-hearts'
  },
  'Ace-diamonds': {
    rank: { shortName: 'A', longName: 'Ace' },
    suit: { name: 'diamonds' },
    id: 'Ace-diamonds'
  },
  'Eight-spades': {
    rank: { shortName: '8', longName: 'Eight' },
    suit: { name: 'spades' },
    id: 'Eight-spades'
  },
  'King-diamonds': {
    rank: { shortName: 'K', longName: 'King' },
    suit: { name: 'diamonds' },
    id: 'King-diamonds'
  },
  'Two-clubs': {
    rank: { shortName: '2', longName: 'Two' },
    suit: { name: 'clubs' },
    id: 'Two-clubs'
  },
  'Jack-diamonds': {
    rank: { shortName: 'J', longName: 'Jack' },
    suit: { name: 'diamonds' },
    id: 'Jack-diamonds'
  },
  'Nine-diamonds': {
    rank: { shortName: '9', longName: 'Nine' },
    suit: { name: 'diamonds' },
    id: 'Nine-diamonds'
  },
  'Five-hearts': {
    rank: { shortName: '5', longName: 'Five' },
    suit: { name: 'hearts' },
    id: 'Five-hearts'
  },
  'Ace-hearts': {
    rank: { shortName: 'A', longName: 'Ace' },
    suit: { name: 'hearts' },
    id: 'Ace-hearts'
  },
  'Four-spades': {
    rank: { shortName: '4', longName: 'Four' },
    suit: { name: 'spades' },
    id: 'Four-spades'
  },
  'Two-spades': {
    rank: { shortName: '2', longName: 'Two' },
    suit: { name: 'spades' },
    id: 'Two-spades'
  },
  'Six-hearts': {
    rank: { shortName: '6', longName: 'Six' },
    suit: { name: 'hearts' },
    id: 'Six-hearts'
  },
  'King-clubs': {
    rank: { shortName: 'K', longName: 'King' },
    suit: { name: 'clubs' },
    id: 'King-clubs'
  },
  'Seven-diamonds': {
    rank: { shortName: '7', longName: 'Seven' },
    suit: { name: 'diamonds' },
    id: 'Seven-diamonds'
  },
  'Two-hearts': {
    rank: { shortName: '2', longName: 'Two' },
    suit: { name: 'hearts' },
    id: 'Two-hearts'
  },
  'Three-diamonds': {
    rank: { shortName: '3', longName: 'Three' },
    suit: { name: 'diamonds' },
    id: 'Three-diamonds'
  },
  'Nine-spades': {
    rank: { shortName: '9', longName: 'Nine' },
    suit: { name: 'spades' },
    id: 'Nine-spades'
  },
  'Nine-diamonds-2': {
    rank: { shortName: '9', longName: 'Nine' },
    suit: { name: 'diamonds' },
    id: 'Nine-diamonds-2'
  },
  'Seven-diamonds-2': {
    rank: { shortName: '7', longName: 'Seven' },
    suit: { name: 'diamonds' },
    id: 'Seven-diamonds-2'
  },
  'Three-spades': {
    rank: { shortName: '3', longName: 'Three' },
    suit: { name: 'spades' },
    id: 'Three-spades'
  },
  'King-diamonds-2': {
    rank: { shortName: 'K', longName: 'King' },
    suit: { name: 'diamonds' },
    id: 'King-diamonds-2'
  },
  'Six-hearts-2': {
    rank: { shortName: '6', longName: 'Six' },
    suit: { name: 'hearts' },
    id: 'Six-hearts-2'
  },
  'Queen-spades': {
    rank: { shortName: 'Q', longName: 'Queen' },
    suit: { name: 'spades' },
    id: 'Queen-spades'
  },
  'Five-clubs': {
    rank: { shortName: '5', longName: 'Five' },
    suit: { name: 'clubs' },
    id: 'Five-clubs'
  },
  'Six-spades': {
    rank: { shortName: '6', longName: 'Six' },
    suit: { name: 'spades' },
    id: 'Six-spades'
  },
  'Jack-clubs-2': {
    rank: { shortName: 'J', longName: 'Jack' },
    suit: { name: 'clubs' },
    id: 'Jack-clubs-2'
  },
  'Ten-spades': {
    rank: { shortName: '10', longName: 'Ten' },
    suit: { name: 'spades' },
    id: 'Ten-spades'
  },
  'Queen-hearts': {
    rank: { shortName: 'Q', longName: 'Queen' },
    suit: { name: 'hearts' },
    id: 'Queen-hearts'
  },
  'Ten-diamonds': {
    rank: { shortName: '10', longName: 'Ten' },
    suit: { name: 'diamonds' },
    id: 'Ten-diamonds'
  },
  'Ten-clubs': {
    rank: { shortName: '10', longName: 'Ten' },
    suit: { name: 'clubs' },
    id: 'Ten-clubs'
  },
  'Nine-spades-2': {
    rank: { shortName: '9', longName: 'Nine' },
    suit: { name: 'spades' },
    id: 'Nine-spades-2'
  },
  'Ten-hearts': {
    rank: { shortName: '10', longName: 'Ten' },
    suit: { name: 'hearts' },
    id: 'Ten-hearts'
  },
  'Queen-spades-2': {
    rank: { shortName: 'Q', longName: 'Queen' },
    suit: { name: 'spades' },
    id: 'Queen-spades-2'
  },
  'Five-spades': {
    rank: { shortName: '5', longName: 'Five' },
    suit: { name: 'spades' },
    id: 'Five-spades'
  },
  'Four-hearts-2': {
    rank: { shortName: '4', longName: 'Four' },
    suit: { name: 'hearts' },
    id: 'Four-hearts-2'
  },
  'Three-diamonds-2': {
    rank: { shortName: '3', longName: 'Three' },
    suit: { name: 'diamonds' },
    id: 'Three-diamonds-2'
  },
  'Ten-spades-2': {
    rank: { shortName: '10', longName: 'Ten' },
    suit: { name: 'spades' },
    id: 'Ten-spades-2'
  },
  'Ace-diamonds-2': {
    rank: { shortName: 'A', longName: 'Ace' },
    suit: { name: 'diamonds' },
    id: 'Ace-diamonds-2'
  },
  'Seven-spades': {
    rank: { shortName: '7', longName: 'Seven' },
    suit: { name: 'spades' },
    id: 'Seven-spades'
  },
  'Six-spades-2': {
    rank: { shortName: '6', longName: 'Six' },
    suit: { name: 'spades' },
    id: 'Six-spades-2'
  },
  'Four-clubs': {
    rank: { shortName: '4', longName: 'Four' },
    suit: { name: 'clubs' },
    id: 'Four-clubs'
  },
  'Two-hearts-2': {
    rank: { shortName: '2', longName: 'Two' },
    suit: { name: 'hearts' },
    id: 'Two-hearts-2'
  },
  'Seven-spades-2': {
    rank: { shortName: '7', longName: 'Seven' },
    suit: { name: 'spades' },
    id: 'Seven-spades-2'
  },
  'Ten-hearts-2': {
    rank: { shortName: '10', longName: 'Ten' },
    suit: { name: 'hearts' },
    id: 'Ten-hearts-2'
  },
  'Ace-spades': {
    rank: { shortName: 'A', longName: 'Ace' },
    suit: { name: 'spades' },
    id: 'Ace-spades'
  },
  'Seven-hearts': {
    rank: { shortName: '7', longName: 'Seven' },
    suit: { name: 'hearts' },
    id: 'Seven-hearts'
  },
  'Five-clubs-2': {
    rank: { shortName: '5', longName: 'Five' },
    suit: { name: 'clubs' },
    id: 'Five-clubs-2'
  },
  'Six-clubs-2': {
    rank: { shortName: '6', longName: 'Six' },
    suit: { name: 'clubs' },
    id: 'Six-clubs-2'
  },
  'Six-diamonds-2': {
    rank: { shortName: '6', longName: 'Six' },
    suit: { name: 'diamonds' },
    id: 'Six-diamonds-2'
  },
  'Five-spades-2': {
    rank: { shortName: '5', longName: 'Five' },
    suit: { name: 'spades' },
    id: 'Five-spades-2'
  },
  'Jack-hearts': {
    rank: { shortName: 'J', longName: 'Jack' },
    suit: { name: 'hearts' },
    id: 'Jack-hearts'
  },
  'Eight-clubs': {
    rank: { shortName: '8', longName: 'Eight' },
    suit: { name: 'clubs' },
    id: 'Eight-clubs'
  },
  'King-hearts': {
    rank: { shortName: 'K', longName: 'King' },
    suit: { name: 'hearts' },
    id: 'King-hearts'
  },
  'Three-hearts-2': {
    rank: { shortName: '3', longName: 'Three' },
    suit: { name: 'hearts' },
    id: 'Three-hearts-2'
  },
  'Three-clubs': {
    rank: { shortName: '3', longName: 'Three' },
    suit: { name: 'clubs' },
    id: 'Three-clubs'
  },
  'Queen-clubs': {
    rank: { shortName: 'Q', longName: 'Queen' },
    suit: { name: 'clubs' },
    id: 'Queen-clubs'
  },
  'Three-spades-2': {
    rank: { shortName: '3', longName: 'Three' },
    suit: { name: 'spades' },
    id: 'Three-spades-2'
  },
  'Eight-spades-2': {
    rank: { shortName: '8', longName: 'Eight' },
    suit: { name: 'spades' },
    id: 'Eight-spades-2'
  },
  'King-spades': {
    rank: { shortName: 'K', longName: 'King' },
    suit: { name: 'spades' },
    id: 'King-spades'
  },
  'Two-diamonds-2': {
    rank: { shortName: '2', longName: 'Two' },
    suit: { name: 'diamonds' },
    id: 'Two-diamonds-2'
  },
  'Eight-diamonds': {
    rank: { shortName: '8', longName: 'Eight' },
    suit: { name: 'diamonds' },
    id: 'Eight-diamonds'
  },
  'Joker-none': {
    rank: { shortName: 'Joker', longName: 'Joker' },
    suit: { name: 'none' },
    id: 'Joker-none'
  },
  'Five-diamonds-2': {
    rank: { shortName: '5', longName: 'Five' },
    suit: { name: 'diamonds' },
    id: 'Five-diamonds-2'
  },
  'Two-clubs-2': {
    rank: { shortName: '2', longName: 'Two' },
    suit: { name: 'clubs' },
    id: 'Two-clubs-2'
  },
  'Ten-clubs-2': {
    rank: { shortName: '10', longName: 'Ten' },
    suit: { name: 'clubs' },
    id: 'Ten-clubs-2'
  },
  'Eight-diamonds-2': {
    rank: { shortName: '8', longName: 'Eight' },
    suit: { name: 'diamonds' },
    id: 'Eight-diamonds-2'
  },
  'Seven-hearts-2': {
    rank: { shortName: '7', longName: 'Seven' },
    suit: { name: 'hearts' },
    id: 'Seven-hearts-2'
  },
  'Two-spades-2': {
    rank: { shortName: '2', longName: 'Two' },
    suit: { name: 'spades' },
    id: 'Two-spades-2'
  },
  'Nine-clubs': {
    rank: { shortName: '9', longName: 'Nine' },
    suit: { name: 'clubs' },
    id: 'Nine-clubs'
  },
  'King-clubs-2': {
    rank: { shortName: 'K', longName: 'King' },
    suit: { name: 'clubs' },
    id: 'King-clubs-2'
  },
  'Four-spades-2': {
    rank: { shortName: '4', longName: 'Four' },
    suit: { name: 'spades' },
    id: 'Four-spades-2'
  },
  'Eight-clubs-2': {
    rank: { shortName: '8', longName: 'Eight' },
    suit: { name: 'clubs' },
    id: 'Eight-clubs-2'
  },
  'Seven-clubs': {
    rank: { shortName: '7', longName: 'Seven' },
    suit: { name: 'clubs' },
    id: 'Seven-clubs'
  },
  'Ace-clubs': {
    rank: { shortName: 'A', longName: 'Ace' },
    suit: { name: 'clubs' },
    id: 'Ace-clubs'
  },
  'Nine-hearts-2': {
    rank: { shortName: '9', longName: 'Nine' },
    suit: { name: 'hearts' },
    id: 'Nine-hearts-2'
  },
  'Joker-none-2': {
    rank: { shortName: 'Joker', longName: 'Joker' },
    suit: { name: 'none' },
    id: 'Joker-none-2'
  },
  'Ace-spades-2': {
    rank: { shortName: 'A', longName: 'Ace' },
    suit: { name: 'spades' },
    id: 'Ace-spades-2'
  },
  'Four-diamonds': {
    rank: { shortName: '4', longName: 'Four' },
    suit: { name: 'diamonds' },
    id: 'Four-diamonds'
  },
  'Eight-hearts': {
    rank: { shortName: '8', longName: 'Eight' },
    suit: { name: 'hearts' },
    id: 'Eight-hearts'
  },
  'Four-clubs-2': {
    rank: { shortName: '4', longName: 'Four' },
    suit: { name: 'clubs' },
    id: 'Four-clubs-2'
  },
  'Nine-clubs-2': {
    rank: { shortName: '9', longName: 'Nine' },
    suit: { name: 'clubs' },
    id: 'Nine-clubs-2'
  },
  'Joker-none-3': {
    rank: { shortName: 'Joker', longName: 'Joker' },
    suit: { name: 'none' },
    id: 'Joker-none-3'
  },
  'Joker-none-4': {
    rank: { shortName: 'Joker', longName: 'Joker' },
    suit: { name: 'none' },
    id: 'Joker-none-4'
  },
  'Three-clubs-2': {
    rank: { shortName: '3', longName: 'Three' },
    suit: { name: 'clubs' },
    id: 'Three-clubs-2'
  },
  'Ace-hearts-2': {
    rank: { shortName: 'A', longName: 'Ace' },
    suit: { name: 'hearts' },
    id: 'Ace-hearts-2'
  },
  'Seven-clubs-2': {
    rank: { shortName: '7', longName: 'Seven' },
    suit: { name: 'clubs' },
    id: 'Seven-clubs-2'
  },
  'Four-diamonds-2': {
    rank: { shortName: '4', longName: 'Four' },
    suit: { name: 'diamonds' },
    id: 'Four-diamonds-2'
  },
  'Ace-clubs-2': {
    rank: { shortName: 'A', longName: 'Ace' },
    suit: { name: 'clubs' },
    id: 'Ace-clubs-2'
  },
  'Queen-hearts-2': {
    rank: { shortName: 'Q', longName: 'Queen' },
    suit: { name: 'hearts' },
    id: 'Queen-hearts-2'
  },
  'Jack-hearts-2': {
    rank: { shortName: 'J', longName: 'Jack' },
    suit: { name: 'hearts' },
    id: 'Jack-hearts-2'
  },
  'Jack-diamonds-2': {
    rank: { shortName: 'J', longName: 'Jack' },
    suit: { name: 'diamonds' },
    id: 'Jack-diamonds-2'
  },
  'Five-hearts-2': {
    rank: { shortName: '5', longName: 'Five' },
    suit: { name: 'hearts' },
    id: 'Five-hearts-2'
  },
  'Eight-hearts-2': {
    rank: { shortName: '8', longName: 'Eight' },
    suit: { name: 'hearts' },
    id: 'Eight-hearts-2'
  },
  'King-hearts-2': {
    rank: { shortName: 'K', longName: 'King' },
    suit: { name: 'hearts' },
    id: 'King-hearts-2'
  },
  'Queen-clubs-2': {
    rank: { shortName: 'Q', longName: 'Queen' },
    suit: { name: 'clubs' },
    id: 'Queen-clubs-2'
  },
  'King-spades-2': {
    rank: { shortName: 'K', longName: 'King' },
    suit: { name: 'spades' },
    id: 'King-spades-2'
  },
  'Ten-diamonds-2': {
    rank: { shortName: '10', longName: 'Ten' },
    suit: { name: 'diamonds' },
    id: 'Ten-diamonds-2'
  }
}
