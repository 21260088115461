import styled from '@emotion/styled'
import { colors } from '../colors'

export const Redirect = styled.header`
  background-color: ${colors.darkBackground};
  border: 4px solid ${colors.lightRed};
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  margin-top: auto;
  flex: 1;
  display: flex;
  max-width: 90vw;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > * {
    padding: 10px;
  }
  @media (max-width: 800px) {
    max-width: 60vw;
    & > div {
      font-size: 2.8vw;
      padding: 0px;
      margin: 0px;
    }
  }
`
