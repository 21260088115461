import { Action, action } from 'easy-peasy'

export interface InfoModel {
  serverURL: string
  setServerURL: Action<InfoModel, string>
  discardSoundRef: HTMLDivElement | null
  setDiscardSoundRef: Action<InfoModel, HTMLDivElement | null>
  shuffleSoundRef: HTMLDivElement | null
  setShuffleSoundRef: Action<InfoModel, HTMLDivElement | null>
  clientURL: string
  setClientURL: Action<InfoModel, string>
  room: string
  setRoom: Action<InfoModel, string>
  loading: boolean
  setLoading: Action<InfoModel, boolean>
}

export const getURL = (env: 'server' | 'client'): string => {
  if (env === 'server')
    return process.env.NODE_ENV === 'development'
      ? window.location.origin.includes('localhost')
        ? 'localhost:8000'
        : '192.168.1.34:8000'
      : 'https://api.canasta.io'
  else
    return process.env.NODE_ENV === 'development'
      ? `http://localhost:3000`
      : `https://master.d1oavtoprkpzct.amplifyapp.com`
}

export const infoModel: InfoModel = {
  serverURL: getURL('server'),
  setServerURL: action((state, url) => {
    state.serverURL = url
  }),
  discardSoundRef: null,
  setDiscardSoundRef: action((state, ref) => {
    state.discardSoundRef = ref
  }),
  shuffleSoundRef: null,
  setShuffleSoundRef: action((state, ref) => {
    state.shuffleSoundRef = ref
  }),
  clientURL: getURL('client'),
  setClientURL: action((state, url) => {
    state.clientURL = url
  }),
  room: '',
  setRoom: action((state, room) => {
    state.room = room
  }),
  loading: false,
  setLoading: action((state, loading) => {
    state.loading = loading
  })
}
