import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import Loader from 'react-loader-spinner'
import { DebugStatus } from '../api'
import { colors } from '../colors'
import { toolButtonStyle } from '../reusable-styles'
import { Row } from './ColumnAndRow'
import { MyButton } from './MyButton'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

export const SendButtonLoader: React.FC<{
  status: DebugStatus
  title: string
  startIcon?: JSX.Element
  onSuccess?: () => any
  onError?: () => any
  onClick: () => any
}> = ({ status, title, startIcon, onSuccess, onError, onClick }) => {
  return (
    <MyButton
      size="tiny"
      style={{
        ...toolButtonStyle,
        backgroundColor: colors.darkBackground,
        color: colors.gold
      }}
      onClick={onClick}
    >
      <Row>
        <div style={{ padding: 2 }}>{title}</div>
        {status === 'loading' && (
          <AppearDiv>
            <Loader
              type="Puff"
              color={colors.lightRed}
              width="20px"
              height="10px"
            />
          </AppearDiv>
        )}
        {status === 'success' && <AppearCheckMark onAnimationEnd={onSuccess} />}
        {status === 'error' && <AppearClose onAnimationEnd={onError} />}
      </Row>
    </MyButton>
  )
}

const appear = keyframes`
    0% {
      width: 0px;
      opacity: 0;
    }
    100% {
      width: 20px;
      opacity: 1;
    }
`
const disappear = keyframes`
   0% {
      width: 20px;
      opacity: 0;
    }
    50% {
      width: 20px;
      opacity: 1;
    }

    100% {
      width: 0px;
      opacity: 1;
    }
`

const AppearDiv = styled.div`
  animation: ${appear} 500ms linear;
`
const AppearCheckMark = styled(AiFillCheckCircle)`
  display: inline;
  color: ${colors.lightGreen};
  animation: ${disappear} 1500ms linear;
`
const AppearClose = styled(AiFillCloseCircle)`
  display: inline;
  color: ${colors.lightRed};
  animation: ${disappear} 1500ms linear;
`
