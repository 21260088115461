import React from 'react'

import './Display.css'

export default class Display extends React.Component<{ value: any }> {
  render() {
    return (
      <div className="component-display">
        <div>{this.props.value}</div>
      </div>
    )
  }
}
