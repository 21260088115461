import styled from '@emotion/styled'
import React, { useState } from 'react'
import { colors as c } from '../colors'

export const Input: React.FC<{
  locked?: boolean
  focused?: boolean
  value?: string
  error?: string
  label?: string
  id: string
  set: React.Dispatch<React.SetStateAction<string>>
}> = ({ value, focused, id, error, label, set, locked }) => {
  const [state, setState] = useState({
    focused: (focused && locked) || false,
    value: value || '',
    error: error || '',
    label: label || ''
  })

  const fieldClassName = `field ${
    (locked ? state.focused : state.focused || value) && 'active'
  } ${locked && !state.focused && 'locked'}`
  return (
    // <div className={fieldClassName} style={{ border: `1px solid ${c.lightRed}` }}>
    <StyledField className={fieldClassName}>
      <input
        id={'1'}
        type="text"
        value={value}
        placeholder={label}
        onChange={(e) => set(e.target.value)}
        onFocus={() =>
          !locked && setState((state) => ({ ...state, focused: true }))
        }
        onBlur={() =>
          !locked && setState((state) => ({ ...state, focused: false }))
        }
      />
      <label htmlFor={'1'}>{label} </label>
    </StyledField>
  )
}

const StyledField = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
  &.active {
    background-color: ${c.lightBackground};
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  }
  &.active input {
    padding: 5px 16px 8px 16px;
  }
  &.active input + label {
    top: 4px;
    opacity: 1;
    color: ${c.lightRed};
  }

  & input {
    width: 100%;
    height: 56px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: ${c.dark};
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
      0.1s padding ease-in-out;
    -webkit-appearance: none;
  }
  & input + label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }

  & input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  & input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  & input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  & input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
`
