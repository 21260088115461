import styled from '@emotion/styled'

import React, { useEffect, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { isMobileOnly, isTablet } from 'react-device-detect'
import { FiSettings } from 'react-icons/fi'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Board } from '../1-reusable/Board'
import { Column, MediaRow } from '../1-reusable/ColumnAndRow'
import { Alert, EasingAlert } from '../1-reusable/EasingAlert'
import { Hand } from '../1-reusable/Hand'
import { MyButton } from '../1-reusable/MyButton'
import { NormalFont } from '../1-reusable/NormalFont'
import { OtherHand } from '../1-reusable/OtherHand'
import { ShowHandsDialog } from '../1-reusable/ShowHandsDialog'
import { ShowPreviousDiscardDialog } from '../1-reusable/ShowPreviousDiscardDialog'
import Calculator from '../3-calculator'
import {
  attemptToRejoinGame,
  DebugStatus,
  listenForReconnection,
  popFromGameHistory,
  receiveGameState,
  receiveSounds,
  refreshSocketAndStore,
  sendDebugEmail,
  sendDiscardSound,
  sendGameState,
  sendShuffleSound
} from '../api'
import { colors as c } from '../colors'
import { cardValuesMap } from '../data'
import {
  calcHandsAndTable,
  checkClose,
  checkGoneDownAndAlterGameState,
  getMobileOrientation,
  getPlayersFromMyContext
} from '../funcs'
import { cardInterfaceMap } from '../store/cardInterfaceMap'
import {
  Group,
  GroupInterfaceMap,
  MyGameInterface,
  Player
} from '../store/game'
import { typedActions, typedState } from '../store/index'
import { toolButtonBreakStyle, toolButtonStyle } from '../reusable-styles'
import { ExpandableButton } from '../1-reusable/ExpandableButton'

const initialAlert: Alert = { type: '', message: '' }

export const Game: React.FC<RouteComponentProps<
  { room: string; clientID: string },
  any,
  { fromJoinGame: boolean }
>> = ({ match, location }) => {
  const history = useHistory()
  const [debugStatus, setDebugStatus] = useState<DebugStatus>('')
  const [alert, setAlert] = useState<Alert>(initialAlert)
  const [hasPickedPack, toggleHasPickedPack] = useState(false)
  const { me, myGame } = typedState((state) => state.game)
  const groups = myGame.groups
  const cards = cardInterfaceMap
  const {
    setHandIDs,
    setMyGroups,
    setGameStatus,
    setShowHandClientID
  } = typedActions((actions) => actions.game)
  const [playersFromMyContext, setPlayersFromMyContext] = useState<Player[]>([])
  const [showTools, setShowTools] = useState(false)
  const [showPreviousDiscard, setShowPreviousDiscard] = useState(false)
  const [showCalculator, setShowCalculator] = useState(false)
  useEffect(() => {
    isMobileOnly &&
      window.addEventListener('orientationchange', () => {
        window.location.reload()
      })
    if (!me.room) {
      attemptToRejoinGame(match.params.room, match.params.clientID)
    }
    listenForReconnection(match.params.room, match.params.clientID)
    receiveGameState()
    receiveSounds()
  }, [])

  useEffect(() => {
    if (myGame.playersInRoom.length) {
      const shiftedPlayers = getPlayersFromMyContext()
      shiftedPlayers && setPlayersFromMyContext(shiftedPlayers)
    }
  }, [myGame.playersInRoom, myGame, me])
  useEffect(() => {
    if (myGame.status === 'finished') {
      setAlert({ type: 'handEnded', message: 'Hand Ended' })
    } else if (me.isMyTurn && myGame.playersInRoom.length) {
      setAlert({ type: 'success', message: "It's Your Turn" })
    } else
      setAlert({
        type: 'normal',
        message: `It's ${myGame.activePlayer.name}'s Turn`
      })
  }, [myGame.activePlayer.clientID, myGame.status, me])

  const pickThePack = () => {
    if (!me.isMyTurn) {
      const previousAlert = alert
      setAlert({ type: 'error', message: "Sorry, it's not your turn!" })
      setTimeout(() => setAlert(previousAlert), 2500)
      return
    }
    const discardGroupIDs = [...groups['discard'].cardIDs]
    const idsToAddToHand: string[] = []

    //looping from top of discard to bottom
    for (let i = discardGroupIDs.length - 1; i >= 0; i--) {
      let id = discardGroupIDs[i]
      if (id.includes('Joker') || id.includes('Two')) {
        break
      }
      idsToAddToHand.push(discardGroupIDs.pop() as string)
    }

    const handGroupIDs = [...groups['hand-' + me.clientID].cardIDs]
    handGroupIDs.push(...idsToAddToHand)

    let newGroups: GroupInterfaceMap = { ...myGame.groups }

    newGroups['discard'].cardIDs = discardGroupIDs
    newGroups['hand-' + me.clientID].cardIDs = handGroupIDs

    setMyGroups(newGroups)
    let newGameState: MyGameInterface = {
      ...myGame,
      groups: newGroups
    }
    sendGameState({ endTurn: false, gameState: newGameState })
    toggleHasPickedPack(true)
  }

  const sortMyHand = () => {
    const handIDs = [...myGame.groups[`hand-${me.clientID}`].cardIDs]
    handIDs.sort((idOne, idTwo) => {
      const mapValueOne = cardValuesMap[cards[idOne].rank.shortName]
      const mapValueTWo = cardValuesMap[cards[idTwo].rank.shortName]
      return mapValueOne - mapValueTWo
    })
    setHandIDs({ handName: `hand-${me.clientID}`, handIDs })
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result

    if (!destination) return
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const startGroup = groups[source.droppableId]
    const endGroup = groups[destination.droppableId]

    if (source.droppableId === destination.droppableId) {
      const newCardIDs = Array.from(startGroup.cardIDs)

      newCardIDs.splice(source.index, 1)
      newCardIDs.splice(destination.index, 0, draggableId)

      const newGroup = { ...startGroup, cardIDs: newCardIDs }
      const newGroups: GroupInterfaceMap = {
        ...groups,
        [destination.droppableId]: newGroup
      }

      setMyGroups(newGroups)
    } else {
      const re = new RegExp(`${me.team}|hand|discard`, 'g')
      const isClosed = checkClose({ group: groups[destination.droppableId] })
      if (me.isMyTurn && re.test(endGroup.id) && !isClosed) {
        // if (endGroup.id === 'discard') {
        //   setMe({ hasDiscarded: true })
        // }
        const newStartCardIDs = Array.from(startGroup.cardIDs)
        newStartCardIDs.splice(source.index, 1)

        const newStartGroup: Group = { ...startGroup, cardIDs: newStartCardIDs }

        const newEndCardIDs = Array.from(endGroup.cardIDs)

        newEndCardIDs.push(draggableId)

        const newEndGroup: Group = { ...endGroup, cardIDs: newEndCardIDs }

        const newGroups: GroupInterfaceMap = {
          ...groups,
          [startGroup.id]: newStartGroup,
          [endGroup.id]: newEndGroup
        }

        let newGameState: MyGameInterface = {
          ...myGame,
          groups: newGroups
        }

        let shouldEndTurn = false
        let endHand = false

        //send gamestate finished if hand is empty

        if (endGroup.id === 'discard') {
          //end hand if last card in a players hand is discarded
          //checking goneDown and drawing cards
          const wentDown = checkGoneDownAndAlterGameState(
            newGameState,
            hasPickedPack
          )

          if (
            !newGameState.groups[`hand-${me.clientID}`].cardIDs.length &&
            !wentDown
          ) {
            endHand = true
          }

          shouldEndTurn = true
          toggleHasPickedPack(false)
          sendDiscardSound()
        }

        setMyGroups(newGameState.groups)
        sendGameState({
          endTurn: shouldEndTurn,
          endHand,
          gameState: newGameState
        })
      } else {
        const previousAlert = alert
        const re = new RegExp(`${me.team}|hand|discard`, 'g')
        let newAlertMessage = ''
        if (isClosed) newAlertMessage = 'Canasta already closed'
        if (!re.test(endGroup.id))
          newAlertMessage = `You can only place cards on the ${me.team} board`
        if (!me.isMyTurn) newAlertMessage = 'Not your turn'

        setAlert({ type: 'error', message: newAlertMessage })
        setTimeout(
          () => alert.type !== 'handEnded' && setAlert(previousAlert),
          2500
        )
      }
    }
  }
  if (!myGame.groups[`hand-${me.clientID}`]) return null
  return (
    <Container
      id="game-container"
      style={{
        marginTop: getMobileOrientation() === 'portrait' ? '-8vh' : undefined
      }}
      onClick={() => showCalculator && setShowCalculator(false)}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Cell style={{}} orientation="top">
          {playersFromMyContext[2] && (
            <Column
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <NormalFont
                style={{
                  fontSize: '.8em',
                  paddingLeft: '8px',
                  marginBottom: '-10px'
                }}
                color={
                  playersFromMyContext[2].team === 'blue'
                    ? c.lightBlue
                    : c.lightRed
                }
              >
                {playersFromMyContext[2].name}
              </NormalFont>
              <OtherHand rotate={180} player={playersFromMyContext[2]} />
            </Column>
          )}
        </Cell>
        <Cell orientation="left">
          {playersFromMyContext[1] && (
            <SideHandRow style={{ width: '6vw' }}>
              <NormalFont
                style={{
                  writingMode: 'vertical-lr',
                  textOrientation: 'mixed'
                }}
                color={
                  playersFromMyContext[1].team === 'blue'
                    ? c.lightBlue
                    : c.lightRed
                }
              >
                {playersFromMyContext[1].name}
              </NormalFont>
              <OtherHand rotate={270} player={playersFromMyContext[1]} />
            </SideHandRow>
          )}
        </Cell>
        <Cell orientation="big">
          <Board />
        </Cell>
        <Cell orientation="right">
          {playersFromMyContext[3] && (
            <SideHandRow>
              <OtherHand rotate={90} player={playersFromMyContext[3]} />
              <NormalFont
                style={{
                  writingMode: 'vertical-lr',
                  textOrientation: 'mixed'
                }}
                color={
                  playersFromMyContext[3].team === 'blue'
                    ? c.lightBlue
                    : c.lightRed
                }
              >
                {playersFromMyContext[3].name}
              </NormalFont>
            </SideHandRow>
          )}
        </Cell>
        <Cell orientation="bottom">
          <Column style={{ justifyContent: 'flex-start' }}>
            <Hand />
            <MediaRow
              style={{
                alignItems: 'flex-start',
                justifyContent: 'space-evenly',
                width: '100%'
              }}
            >
              <MyButton
                size="tiny"
                style={{ width: '100%' }}
                onClick={() => {
                  setShowHandClientID(me.clientID)
                  sendGameState({ endTurn: false })
                }}
              >
                Show My Hand
              </MyButton>
              <MyButton
                size={'tiny'}
                style={{ width: '100%' }}
                onClick={() => {
                  pickThePack()
                }}
              >
                Pick The Pack
              </MyButton>
              <MyButton
                size={'tiny'}
                style={{ width: '100%' }}
                onClick={() => {
                  setShowPreviousDiscard(true)
                }}
              >
                Peek Previous Discard
              </MyButton>
              <MyButton
                size={'tiny'}
                style={{ width: '100%' }}
                onClick={() => {
                  sortMyHand()
                }}
              >
                Sort Cards
              </MyButton>
              <MyButton
                size={'tiny'}
                style={{ width: '100%' }}
                onClick={() => {
                  if (me.isMyTurn || myGame.status === 'finished') {
                    popFromGameHistory()
                  } else {
                    const previousAlert = alert
                    setAlert({
                      type: 'error',
                      message: 'You Can Only Undo On Your Turn'
                    })
                    setTimeout(() => setAlert(previousAlert), 2500)
                  }
                }}
              >
                Undo
              </MyButton>
              <NormalFont
                style={{
                  fontSize: '.9em',
                  alignSelf: 'center',
                  backgroundColor: c.lightRed,
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  textAlign: 'center',
                  marginLeft: '4px',
                  marginRight: '4px',
                  borderRadius: '10px'
                }}
              >
                {myGame.groups[`hand-${me.clientID}`].cardIDs.length}
              </NormalFont>
            </MediaRow>
          </Column>
        </Cell>
      </DragDropContext>
      <Column
        style={{
          position: 'absolute',
          top: isTablet ? '110px' : '60px',
          left: '10px',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <SettingsButton
          onClick={() => {
            setShowTools(!showTools)
          }}
        />

        {showTools && (
          <EaseIn id="toolbox">
            <div style={{ height: 5 }} />
            <MyButton
              size="tiny"
              style={toolButtonStyle}
              onClick={() => {
                setShowCalculator(!showCalculator)
                setGameStatus('finished')
                sendGameState({ endTurn: false })
              }}
            >
              Calculator (score base/splashes)
            </MyButton>
            <MyButton
              size="tiny"
              style={{
                ...toolButtonStyle,
                backgroundColor: c.extraLightRed,
                color: c.darkRed,
                border: `2px solid ${c.darkRed}`
              }}
              onClick={() => {
                calcHandsAndTable('red')
                setGameStatus('finished')
                sendGameState({ endTurn: false })
              }}
            >
              Red points (score table – hands)
            </MyButton>
            <MyButton
              size="tiny"
              style={{
                ...toolButtonStyle,
                backgroundColor: c.lightBlue,
                color: c.darkBlue,
                border: `2px solid ${c.darkBlue}`
              }}
              onClick={() => {
                calcHandsAndTable('blue')
                setGameStatus('finished')
                sendGameState({ endTurn: false })
              }}
            >
              Blue points (score table – hands)
            </MyButton>
            <br />

            <MyButton
              size="tiny"
              style={toolButtonStyle}
              onClick={() => {
                sendGameState({ endTurn: false, nextHand: true })
                sendShuffleSound()
              }}
            >
              &#8594; Next Hand
            </MyButton>
            <MyButton
              size="tiny"
              style={{ ...toolButtonStyle, color: c.lightRed }}
              onClick={() => {
                sendGameState({ endTurn: false, restart: true })
                sendShuffleSound()
              }}
            >
              &#8635; New Game
            </MyButton>
            <br />
            <MyButton
              size="tiny"
              style={{
                ...toolButtonStyle,
                backgroundColor: c.darkBackground,
                color: c.gold
              }}
              onClick={() => {
                window.location.reload()
              }}
            >
              Refresh Page
            </MyButton>
            <MyButton
              size="tiny"
              style={{
                ...toolButtonStyle,
                backgroundColor: c.darkBackground,
                color: c.gold
              }}
              onClick={() => {
                refreshSocketAndStore()
                history.push('/')
              }}
            >
              Home
            </MyButton>
            <ExpandableButton />
          </EaseIn>
        )}
      </Column>
      <ShowHandsDialog />
      <Calculator
        style={{
          zIndex: 2,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: '500px',
          height: '500px'
        }}
        visible={showCalculator}
      />
      <div
        style={{
          zIndex: 2,
          position: 'absolute',
          top: isTablet ? '80px' : '25px',
          left: '10px',
          color: c.lightRed
        }}
      >
        {getMobileOrientation() !== 'landscape' && <span>Red Team Score:</span>}{' '}
        {myGame.teamInfo['red'].score}
      </div>
      <div
        style={{
          zIndex: 2,
          position: 'absolute',
          top: isTablet ? '80px' : '25px',
          right: '10px',
          color: c.lightBlue
        }}
      >
        {getMobileOrientation() !== 'landscape' && (
          <span>Blue Team Score:</span>
        )}
        {myGame.teamInfo['blue'].score}
      </div>
      <EasingAlert alert={alert}>{alert.message}</EasingAlert>
      {showCalculator && <Overlay />}
      <ShowPreviousDiscardDialog
        showPreviousDiscard={showPreviousDiscard}
        setShowPreviousDiscard={setShowPreviousDiscard}
      />
    </Container>
  )
}

const EaseIn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 2;
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
  animation: growDown 200ms ease-in-out forwards;
  transform-origin: top center;
`

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
`

const Container = styled.div`
  display: grid;
  max-height: 98vh;
  max-width: 100vw;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'top top top top top'
    'left big big big right'
    'left big big big right'
    'left big big big right'
    'bottom bottom bottom bottom bottom';
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
`

const Cell = styled.div<{ orientation: string }>`
  justify-self: ${({ orientation }) =>
    orientation === 'left'
      ? 'flex-start'
      : orientation === 'right'
      ? 'flex-end'
      : 'center'};
  align-self: ${({ orientation }) =>
    orientation === 'top' ? 'flex-start' : 'auto'};
  justify-content: flex-start;
  grid-area: ${({ orientation }) => orientation};
`

const SettingsButton = styled(FiSettings)`
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    box-shadow: 0 0 10px white;
  }
  border-radius: 30px;
  padding: 5px;
`

const SideHandRow = styled(MediaRow)`
  && {
    justify-content: space-between;
  }
`
