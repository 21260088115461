export type SingleSpotLabel = { label: string; colorLabel: string }

export type SpotLabels = {
  red: SingleSpotLabel[][]
  blue: SingleSpotLabel[][]
}

export const spotLabels: SpotLabels = {
  red: [
    [
      { label: 'Wild', colorLabel: 'redWild' },
      { label: 'Eight', colorLabel: 'redEight' }
    ],
    [
      { label: 'Three', colorLabel: 'redThree' },
      { label: 'Nine', colorLabel: 'redNine' }
    ],
    [
      { label: 'Four', colorLabel: 'redFour' },
      { label: 'Ten', colorLabel: 'redTen' }
    ],
    [
      { label: 'Five', colorLabel: 'redFive' },
      { label: 'Jack', colorLabel: 'redJack' }
    ],
    [
      { label: 'Six', colorLabel: 'redSix' },
      { label: 'Queen', colorLabel: 'redQueen' }
    ],
    [
      { label: 'Seven', colorLabel: 'redSeven' },
      { label: 'King', colorLabel: 'redKing' }
    ],
    [{ label: 'Ace', colorLabel: 'redAce' }]
  ],
  blue: [
    [
      { label: 'Wild', colorLabel: 'blueWild' },
      { label: 'Eight', colorLabel: 'blueEight' }
    ],
    [
      { label: 'Three', colorLabel: 'blueThree' },
      { label: 'Nine', colorLabel: 'blueNine' }
    ],
    [
      { label: 'Four', colorLabel: 'blueFour' },
      { label: 'Ten', colorLabel: 'blueTen' }
    ],
    [
      { label: 'Five', colorLabel: 'blueFive' },
      { label: 'Jack', colorLabel: 'blueJack' }
    ],
    [
      { label: 'Six', colorLabel: 'blueSix' },
      { label: 'Queen', colorLabel: 'blueQueen' }
    ],
    [
      { label: 'Seven', colorLabel: 'blueSeven' },
      { label: 'King', colorLabel: 'blueKing' }
    ],
    [{ label: 'Ace', colorLabel: 'blueAce' }]
  ]
}

export const closedSpotLabeLMap: { [index: string]: string } = {
  Wild: 'W',
  Eight: '8',
  Three: '3',
  Nine: '9',
  Four: '4',
  Ten: '10',
  Five: '5',
  Jack: 'J',
  Six: '6',
  Queen: 'Q',
  Seven: '7',
  King: 'K',
  Ace: 'A'
}

export const cardValuesMap: { [index: string]: number } = {
  '2': 2,
  Joker: 3,
  A: 4,
  K: 5,
  Q: 6,
  J: 7,
  '10': 8,
  '9': 9,
  '8': 10,
  '7': 11,
  '6': 12,
  '5': 13,
  '4': 14,
  '3': 15
}
