import styled from '@emotion/styled'
export const CardGroupContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  max-width: 100%;
  .cardWrapper:last-child,
  .cardWrapper:hover {
  }
  .cardWrapper {
    overflow: hidden;
  }
`
