import {
  Action,
  action,
  createStore,
  createTypedHooks,
  Store
} from 'easy-peasy'
import { gimme } from 'gimme-state'
import { gameModel, GameModel } from './game'
import { infoModel, InfoModel } from './info'

const development: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

let middleware = development ? [gimme] : []

type RootStoreState = { info: InfoModel; game: GameModel; reset: Action }

const typedHooks = createTypedHooks<{
  info: InfoModel
  game: GameModel
}>()
export const typedActions = typedHooks.useStoreActions
export const typedState = typedHooks.useStoreState

export const store: Store<RootStoreState> = createStore(
  {
    info: infoModel,
    game: gameModel,
    reset: action((state) => (state = initialStoreState))
  },
  { middleware }
)

export const initialStoreState = store.getState()
// export const store = createStore({
//   info: infoModel,
//   game: gameModel
// })
