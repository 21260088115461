import styled from '@emotion/styled'
import { colors as c } from '../colors'

export const MyButton = styled.button<{ size?: 'small' | 'tiny' }>`
  color: ${c.darkBlue};
  z-index: 2;
  white-space: nowrap;
  padding: ${({ size }) =>
    size === 'tiny' ? '3px' : size === 'small' ? '8px 8px' : '16px'};
  border: 2px solid ${c.lightRed};
  background-color: ${c.lightBackground};
  border-radius: 15px;
  font-size: ${({ size }) =>
    size === 'tiny' ? '1.1rem' : size === 'small' ? '1.3rem' : '1.8rem'};
  height: ${({ size }) => (size === 'small' ? 'auto' : 'auto')};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    box-shadow: 0 0 10px white;
  }
  @media (max-width: 1100px) {
    font-size: 80%;
  }
  @media (max-width: 800px) {
    font-size: 70%;
  }
  @media (max-width: 500px) {
    font-size: 60%;
  }
`

export const HomeButton = styled(MyButton)`
  background-color: ${c.darkBackground};
  color: ${c.gold};
`
