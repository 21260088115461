import styled from '@emotion/styled'
import React from 'react'
import { Player } from '../store/game'
import { typedState } from '../store/index'
import { CardBack } from './Card'
export const OtherHand: React.FC<{
  rotate: 90 | 180 | 270
  player: Player
}> = ({ rotate, player }) => {
  const groups = typedState((state) => state.game.myGame.groups)

  if (!player) return null

  if (rotate === 90 || rotate === 270) {
    return (
      <Container
        style={{ flexDirection: 'column', maxHeight: '50vh', width: '6vw' }}
      >
        {groups['hand-' + player.clientID].cardIDs.map((id, idx, ids) => {
          return (
            <CardWrapper key={id} rotate={rotate}>
              <CardBack
                lastCard={idx === ids.length - 1}
                numberOfCards={ids.length}
                style={{
                  maxWidth: '50px',
                  maxHeight: '60px',
                  width: '4vw',
                  height: '7vw'
                }}
                hand
              />
            </CardWrapper>
          )
        })}
      </Container>
    )
  }
  return (
    <Container>
      {groups['hand-' + player.clientID].cardIDs.map((id, idx, ids) => {
        return (
          <CardWrapper key={id} rotate={rotate}>
            <CardBack
              lastCard={idx === ids.length - 1}
              numberOfCards={ids.length}
              style={{
                maxWidth: '50px',
                maxHeight: '60px',
                width: '4vh',
                height: '7vh'
              }}
              hand
            />
          </CardWrapper>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 30vw;
  z-index: 1;
`

const CardWrapper = styled.div<{ rotate?: number }>`
  padding: ${({ rotate }) => {
    if (rotate === 90 || rotate === 270) return '0px 8px 0px 8px'
    else return '8px 0px 8px 0px'
  }};
  overflow: hidden;
  &:last-child {
    overflow: visible;
  }
`
