import React from 'react'
import { MediaRow } from '../../1-reusable/ColumnAndRow'
import { MyButton } from '../../1-reusable/MyButton'
import { sendGameState } from '../../api'
import { colors as c } from '../../colors'
import { store } from '../../store/index'
import calculate from '../logic/calculate'
import './App.css'
import ButtonPanel from './ButtonPanel'
import Display from './Display'

export default class App extends React.Component<
  {
    visible: boolean
    style: React.CSSProperties
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = { total: null, next: null, operation: null }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(buttonName: string) {
    let results = calculate(this.state, buttonName)
    this.setState(results)
  }

  render() {
    if (!this.props.visible) return null
    return (
      <MediaRow
        style={{ ...this.props.style, justifyContent: 'space-between' }}
      >
        <MyButton
          onClick={(e) => {
            e.stopPropagation()

            store.getActions().game.changeScores({
              score: Number(this.state.next || this.state.total || 0),
              team: 'red'
            })
            sendGameState({ endTurn: false })
            this.setState({ total: 0, next: 0 })
          }}
          style={{
            whiteSpace: 'normal',
            margin: '10px',
            backgroundColor: c.extraLightRed,
            color: c.darkRed,
            border: `2px solid ${c.darkRed}`
          }}
          size="tiny"
        >
          &#8592; Add To Red Team
        </MyButton>
        <div onClick={(e) => e.stopPropagation()} className="component-app">
          <Display value={this.state.next || this.state.total || '0'} />
          <ButtonPanel clickHandler={this.handleClick} />
        </div>
        <MyButton
          onClick={(e) => {
            e.stopPropagation()

            store.getActions().game.changeScores({
              score: Number(this.state.next || this.state.total || 0),
              team: 'blue'
            })
            sendGameState({ endTurn: false })
            this.setState({ total: 0, next: 0 })
          }}
          style={{
            whiteSpace: 'normal',
            margin: '10px',
            backgroundColor: c.lightBlue,
            color: c.darkBlue,
            border: `2px solid ${c.darkBlue}`
          }}
          size="tiny"
        >
          Add To Blue Team &#8594;
        </MyButton>
      </MediaRow>
    )
  }
}
