import React from 'react'
import UIFx from 'uifx'
import { typedActions } from '../store/index'
const discard = require('../sounds/discard.wav')
const shuffle = require('../sounds/shuffle.wav')

const discardSound = new UIFx(discard)
const shuffleSound = new UIFx(shuffle)

export const Sounds: React.FC<{
  discardRef: HTMLDivElement | null
  shuffleRef: HTMLDivElement | null
}> = ({}) => {
  const setDiscardSoundRef = typedActions(
    (state) => state.info.setDiscardSoundRef
  )
  const setShuffleSoundRef = typedActions(
    (state) => state.info.setShuffleSoundRef
  )
  return (
    <>
      <div
        ref={(r) => setDiscardSoundRef(r)}
        onClick={() => {
          discardSound.play()
        }}
      />
      <div
        ref={(r) => setShuffleSoundRef(r)}
        onClick={() => {
          shuffleSound.play()
        }}
      />
    </>
  )
}
