import styled from '@emotion/styled'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

export const MediaRow = styled.div<{ dynamic?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  @media (max-width: 800px) and (max-aspect-ratio: 6/5) {
    flex-direction: ${({ dynamic }) => (dynamic ? 'column' : 'row')};
    justify-items: flex-end;
    & > * > * > * {
      font-size: ${({ dynamic }) => (dynamic ? '.8em' : undefined)};
      margin-top: ${({ dynamic }) => (dynamic ? '-7px' : undefined)};
      margin-bottom: ${({ dynamic }) => (dynamic ? '-7px' : undefined)};
    }
  }
`
