import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import { colors as c } from '../colors'

interface P {
  name: string
  blue?: boolean
  children?: React.ReactNode
  isDraggingOver?: boolean
  almostClosed?: boolean
}

export const CardSpot = React.forwardRef(
  (
    { name, blue, children, isDraggingOver, almostClosed = false }: P,
    ref: any
  ) => {
    return (
      <CardContainer
        almostClosed={almostClosed}
        isDraggingOver={isDraggingOver || false}
        ref={ref || null}
        blue={blue || false}
      >
        {name && <DivC>{name}</DivC>}
        {!name && children}
      </CardContainer>
    )
  }
)

interface DivCProps {
  children?: ReactNode
  rotate?: boolean
  info?: boolean
}

const DivC = styled.div<DivCProps>`
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 3.5vmin;
`

const CardContainer = styled.div<{
  blue: boolean
  isDraggingOver: boolean
  almostClosed: boolean
}>`
  border: ${({ blue, almostClosed }) =>
    blue
      ? `${almostClosed ? `3px` : `1px`} solid ${c.lightBlue}`
      : `${almostClosed ? `3px` : `1px`} solid ${c.lightRed}`};
  animation: ${({ almostClosed }) => (almostClosed ? 'pulse 2s infinite' : '')};
  border-radius: 10px;
  font-family: Dancing Script, cursive;
  background-color: ${({ isDraggingOver, blue, almostClosed }) =>
    isDraggingOver || almostClosed
      ? blue
        ? c.lightBlue
        : c.lightRed
      : undefined};

  width: 13vw;
  max-width: 50%;
  height: 8vmin;
  text-align: center;
  justify-content: center;
  display: flex;
  color: ${c.lightBackground};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
  }
`
