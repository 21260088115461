import React from 'react'
import styled from '@emotion/styled'
import { Column } from './ColumnAndRow'
import { NormalFont } from './NormalFont'
import { typedState } from '../store/index'
import { colors as c } from '../colors'
import { Card } from './Card'
import { MdClose } from 'react-icons/md'
import { cardInterfaceMap } from '../store/cardInterfaceMap'

export const ShowPreviousDiscardDialog: React.FC<{
  showPreviousDiscard: boolean
  setShowPreviousDiscard: (value: React.SetStateAction<boolean>) => void
}> = ({ showPreviousDiscard, setShowPreviousDiscard }) => {
  const myGame = typedState((state) => state.game.myGame)
  const discard = myGame.groups['discard']
  const cards = cardInterfaceMap
  if (!showPreviousDiscard) return null
  return (
    <Container>
      <TopLeft onClick={() => setShowPreviousDiscard(false)}>
        <CloseButton />
      </TopLeft>
      <Column
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }}
      >
        <HandContainer>
          <CardWrapper>
            <Card
              size="tiny"
              card={cards[discard.cardIDs[discard.cardIDs.length - 2]]}
              hand
            />
          </CardWrapper>
        </HandContainer>
      </Column>
    </Container>
  )
}

const CloseButton = styled(MdClose)`
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    box-shadow: 0 0 10px white;
  }
  border-radius: 30px;
  padding: 5px;
`

const TopLeft = styled.div`
  position: fixed;
  z-index: 3;
  top: 5px;
  left: 5px;
  color: ${c.lightBackground};
`

const Container = styled.div`
  background-color: ${c.darkBackgroundTranslucent};
  border: 1px solid ${c.lightBackground};
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 95vw;
  max-height: 95vh;
`

const HandContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  max-width: 70vw;
  min-width: 200px;
  .cardWrapper:last-child {
    overflow: visible;
  }
  .cardWrapper:hover {
  }
  .cardWrapper {
  }
`

const CardWrapper = styled.div<{ isDragging?: boolean }>`
  padding-top: 8px;
  padding-bottom: 8px;
  overflow: ${({ isDragging }) => (isDragging ? 'visible' : 'hidden')};
  &:hover {
    padding-left: 2px;
    padding-right: 2px;
  }
  &:last-child {
    overflow: visible;
  }
`
