import React from 'react'
import {
  GiAbstract001,
  GiCardJoker,
  GiClubs,
  GiDiamonds,
  GiHearts,
  GiSpades
} from 'react-icons/gi'
import { colors as c } from '../colors'

interface P {
  suit?: 'spades' | 'hearts' | 'diamonds' | 'clubs' | 'none'
  colors?: { red: string; dark: string }
  size?: number | string
}

export const Symbol: React.FC<P> = ({
  suit = '',
  colors = { red: c.lightRed, dark: c.dark },
  size = '80%'
}) => {
  if (!suit) return <GiAbstract001 color={c.lightBackground} size={size} />
  if (suit === 'spades') return <GiSpades color={colors.dark} size={size} />
  else if (suit === 'hearts') return <GiHearts color={colors.red} size={size} />
  else if (suit === 'diamonds')
    return <GiDiamonds color={colors.red} size={size} />
  else if (suit === 'clubs') return <GiClubs color={colors.dark} size={size} />
  else if (suit === 'none')
    return <GiCardJoker color={colors.red} size={size} />
  else return null
}

export default Symbol
