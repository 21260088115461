import React from 'react'

export const toolButtonStyle: React.CSSProperties = {
  marginTop: 2,
  marginBottom: 2
}

export const toolButtonBreakStyle: React.CSSProperties = {
  height: '10vmin'
}
