import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { colors as c } from '../colors'

export interface Alert {
  type: 'error' | 'success' | 'normal' | 'handEnded' | ''
  message: string
}
export const fadeIn = keyframes`
  from {
    transform: opacity(0.25);
    opacity: 0;
  }

  to {
    transform: opacity(1);
    opacity: 1;
  }
`

export const fadeOut = keyframes`
  from {
    transform: opacity(1);
    opacity: 1;
  }

  to {
    transform: opacity(0.25);
    opacity: 0;
  }
`

export const EasingAlert = styled.div<{ alert: Alert }>`
  position: absolute;
  font-family: Roboto, Lato, Arial, Arial, Helvetica, sans-serif;
  font-size: ${({ alert }) =>
    alert.message === "It's Your Turn" ? '1.2em' : '1em'};
  bottom: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ alert }) => {
    switch (alert.type) {
      case 'error':
        return 'red'
      case 'handEnded':
        return 'gold'
      case 'normal':
        return 'white'
      case 'success':
        return 'lightgreen'
      default:
        return 'white'
    }
  }};

  visibility: ${({ alert }) => (alert.message ? 'visible' : 'hidden')};
  animation: ${fadeIn} 0.5s linear;
  transition: opacity 1s linear;
  animation: ${({ alert }) =>
    alert.message === "It's Your Turn" ? 'pulseWords 1s infinite' : ''};
  border: 1 px solid ${c.darkBackground};
  background-color: ${c.darkBackground};
  border-radius: 15px;
  @keyframes pulseWords {
    0% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }
  @media (max-width: 800px) and (max-aspect-ratio: 6/5) {
    font-size: 0.7em;
  }
`
