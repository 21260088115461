import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { isTablet } from 'react-device-detect'
import { sendGameState } from '../api'
import { colors as c } from '../colors'
import { closedSpotLabeLMap, spotLabels } from '../data'
import { checkClose } from '../funcs'
import { cardInterfaceMap } from '../store/cardInterfaceMap'
import {
  CardInterface,
  Group,
  GroupInterfaceMap,
  MyGameInterface,
  SortableCards
} from '../store/game'
import { typedActions, typedState } from '../store/index'
import { Card, CardBack } from './Card'
import { CardGroupContainer } from './CardGroupContainer'
import { CardSpot } from './CardSpot'
import { Column, MediaRow } from './ColumnAndRow'
import { MyButton } from './MyButton'
import { ShowWildCanastaDialog } from './ShowWildCanastaDialog'

export const Board: React.FC = () => {
  const { myGame, me } = typedState((state) => state.game)
  const groups = myGame.groups
  const cards = cardInterfaceMap
  const [lastCard, setLastCard] = useState<CardInterface>({} as CardInterface)
  const [topCard, setTopCard] = useState<CardInterface>({} as CardInterface)
  const [
    selectedWildCanastaCardGroup,
    setSelectedWildCanastaCardGroup
  ] = useState<Group | undefined>(undefined)
  const { setMyGroups } = typedActions((actions) => actions.game)

  React.useEffect(() => {
    const lastCardID =
      groups['discard']?.cardIDs[groups['discard']?.cardIDs.length - 1]
    const topCardID =
      groups['deck']?.cardIDs[groups['deck']?.cardIDs.length - 1]
    setLastCard(cards[lastCardID])
    setTopCard(cards[topCardID])
  }, [groups])

  const handleDraw = () => {
    if (me.isMyTurn) {
      const deck = groups['deck']
      if (!deck.cardIDs.length) return
      const hand = groups[`hand-${me.clientID}`]
      const newGroups: GroupInterfaceMap = {
        ...groups,
        deck: { ...deck, cardIDs: deck.cardIDs.slice(0, -1) },
        [`hand-${me.clientID}`]: {
          ...hand,
          cardIDs: [...hand.cardIDs, deck.cardIDs[deck.cardIDs.length - 1]]
        }
      }
      setMyGroups(newGroups)
      let newGameState: MyGameInterface = {
        ...myGame,
        groups: newGroups
      }
      sendGameState({ endTurn: false, gameState: newGameState })
    } else {
      alert('Not Your Turn')
    }
  }

  //if (!lastCard.id) return null

  return (
    <Container>
      <CardSpotColumn>
        {spotLabels.red.map((labelGroup, idx) => {
          return (
            <CardSpotRow key={labelGroup[0].colorLabel}>
              <Droppable
                droppableId={labelGroup[0].colorLabel}
                direction={'horizontal'}
              >
                {(provided, snapshot) => (
                  <CardSpot
                    almostClosed={
                      groups[labelGroup[0].colorLabel].cardIDs.length === 6 &&
                      !labelGroup[0].colorLabel.includes('Three')
                    }
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    name={
                      groups[labelGroup[0].colorLabel].cardIDs.length
                        ? ''
                        : labelGroup[0].label
                    }
                  >
                    <CardGroupContainer>
                      {checkClose({
                        group: groups[labelGroup[0].colorLabel]
                      }) ? (
                        <MediaRow>
                          {myGame.status === 'finished' && (
                            <ShowCanastaButton
                              size="tiny"
                              onClick={() => {
                                setSelectedWildCanastaCardGroup(
                                  groups[labelGroup[0].colorLabel]
                                )
                              }}
                            >
                              Show
                            </ShowCanastaButton>
                          )}
                          <ClosedIndicator
                            includesWild={groups[
                              labelGroup[0].colorLabel
                            ].cardIDs.some(
                              (id) => id.includes('Joker') || id.includes('Two')
                            )}
                          >
                            {closedSpotLabeLMap[labelGroup[0].label]}
                          </ClosedIndicator>
                        </MediaRow>
                      ) : (
                        groups[labelGroup[0].colorLabel].cardIDs.map(
                          (id, index, source) => {
                            return (
                              <div className="cardWrapper" key={id}>
                                <Card
                                  sideways={index === source.length - 1}
                                  size="extra-tiny"
                                  hand
                                  card={cards[id]}
                                />
                              </div>
                            )
                          }
                        )
                      )}
                    </CardGroupContainer>
                  </CardSpot>
                )}
              </Droppable>
              {labelGroup[1] && (
                <Droppable
                  droppableId={labelGroup[1].colorLabel}
                  direction={'horizontal'}
                >
                  {(provided, snapshot) => (
                    <CardSpot
                      almostClosed={
                        groups[labelGroup[1].colorLabel].cardIDs.length === 6 &&
                        !labelGroup[1].colorLabel.includes('Three')
                      }
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                      name={
                        groups[labelGroup[1].colorLabel].cardIDs.length
                          ? ''
                          : labelGroup[1].label
                      }
                    >
                      <CardGroupContainer>
                        {checkClose({
                          group: groups[labelGroup[1].colorLabel]
                        }) ? (
                          <MediaRow>
                            {myGame.status === 'finished' && (
                              <ShowCanastaButton
                                size="tiny"
                                onClick={() => {
                                  setSelectedWildCanastaCardGroup(
                                    groups[labelGroup[1].colorLabel]
                                  )
                                }}
                              >
                                Show
                              </ShowCanastaButton>
                            )}
                            <ClosedIndicator
                              includesWild={groups[
                                labelGroup[1].colorLabel
                              ].cardIDs.some(
                                (id) =>
                                  id.includes('Joker') || id.includes('Two')
                              )}
                            >
                              {closedSpotLabeLMap[labelGroup[1].label]}
                            </ClosedIndicator>
                          </MediaRow>
                        ) : (
                          groups[labelGroup[1].colorLabel].cardIDs.map((id) => {
                            return (
                              <div className="cardWrapper" key={id}>
                                <Card size="extra-tiny" hand card={cards[id]} />
                              </div>
                            )
                          })
                        )}
                      </CardGroupContainer>
                    </CardSpot>
                  )}
                </Droppable>
              )}
            </CardSpotRow>
          )
        })}
      </CardSpotColumn>
      <CardSpotColumn middle>
        <CardSpotRow style={{ justifyContent: 'space-between' }}>
          <Column style={{ width: '100%' }}>
            <MediaRow dynamic style={{ justifyContent: 'center' }}>
              <Column style={{ margin: '8px' }}>
                <Droppable droppableId="deck" direction="horizontal">
                  {(provided, snapshot) => {
                    return (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div>Deck</div>
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <CardBack
                            empty={!groups['deck'].cardIDs.length}
                            style={{ width: '9vmin', height: '14vmin' }}
                          />
                        </div>
                        {snapshot.draggingFromThisWith && (
                          <CardBack
                            style={{ width: '9vmin', height: '14vmin' }}
                          />
                        )}
                        <div>{groups['deck'].cardIDs.length}</div>
                      </div>
                    )
                  }}
                </Droppable>
              </Column>
              <Column style={{ margin: '8px' }}>
                <Droppable droppableId="discard">
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          backgroundColor: snapshot.isDraggingOver
                            ? 'black'
                            : undefined,

                          borderRadius: '10px'
                        }}
                      >
                        <div>Discard</div>
                        <Card
                          card={lastCard}
                          style={{ width: '9vmin', height: '14vmin' }}
                        />
                        <div>{groups['discard'].cardIDs.length}</div>
                      </div>
                    )
                  }}
                </Droppable>
              </Column>
            </MediaRow>

            <MyButton
              style={{
                height: '9vmin',
                width: '14vmin',
                fontSize: '3.5vmin',
                textAlign: 'center',
                padding: 0
              }}
              onClick={handleDraw}
            >
              Draw
            </MyButton>
          </Column>
        </CardSpotRow>
      </CardSpotColumn>
      <CardSpotColumn>
        {spotLabels.blue.map((labelGroup, idx) => {
          return (
            <CardSpotRow key={idx}>
              <Droppable
                droppableId={labelGroup[0].colorLabel}
                direction={'horizontal'}
              >
                {(provided, snapshot) => (
                  <CardSpot
                    almostClosed={
                      groups[labelGroup[0].colorLabel].cardIDs.length === 6 &&
                      !labelGroup[0].colorLabel.includes('Three')
                    }
                    {...provided.droppableProps}
                    blue
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    name={
                      groups[labelGroup[0].colorLabel].cardIDs.length
                        ? ''
                        : labelGroup[0].label
                    }
                  >
                    <CardGroupContainer>
                      {checkClose({
                        group: groups[labelGroup[0].colorLabel]
                      }) ? (
                        <MediaRow>
                          {myGame.status === 'finished' && (
                            <ShowCanastaButton
                              size="tiny"
                              onClick={() => {
                                setSelectedWildCanastaCardGroup(
                                  groups[labelGroup[0].colorLabel]
                                )
                              }}
                            >
                              Show
                            </ShowCanastaButton>
                          )}
                          <ClosedIndicator
                            includesWild={groups[
                              labelGroup[0].colorLabel
                            ].cardIDs.some(
                              (id) => id.includes('Joker') || id.includes('Two')
                            )}
                          >
                            {closedSpotLabeLMap[labelGroup[0].label]}
                          </ClosedIndicator>
                        </MediaRow>
                      ) : (
                        groups[labelGroup[0].colorLabel].cardIDs.map((id) => {
                          return (
                            <div className="cardWrapper" key={id}>
                              <Card size="extra-tiny" hand card={cards[id]} />
                            </div>
                          )
                        })
                      )}
                    </CardGroupContainer>
                  </CardSpot>
                )}
              </Droppable>
              {labelGroup[1] && (
                <Droppable
                  droppableId={labelGroup[1].colorLabel}
                  direction={'horizontal'}
                >
                  {(provided, snapshot) => (
                    <CardSpot
                      almostClosed={
                        groups[labelGroup[1].colorLabel].cardIDs.length === 6 &&
                        !labelGroup[1].colorLabel.includes('Three')
                      }
                      {...provided.droppableProps}
                      blue
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                      name={
                        groups[labelGroup[1].colorLabel].cardIDs.length
                          ? ''
                          : labelGroup[1].label
                      }
                    >
                      <CardGroupContainer>
                        {checkClose({
                          group: groups[labelGroup[1].colorLabel]
                        }) ? (
                          <MediaRow>
                            {myGame.status === 'finished' && (
                              <ShowCanastaButton
                                size="tiny"
                                onClick={() => {
                                  setSelectedWildCanastaCardGroup(
                                    groups[labelGroup[1].colorLabel]
                                  )
                                }}
                              >
                                Show
                              </ShowCanastaButton>
                            )}
                            <ClosedIndicator
                              includesWild={groups[
                                labelGroup[1].colorLabel
                              ].cardIDs.some(
                                (id) =>
                                  id.includes('Joker') || id.includes('Two')
                              )}
                            >
                              {closedSpotLabeLMap[labelGroup[1].label]}
                            </ClosedIndicator>
                          </MediaRow>
                        ) : (
                          groups[labelGroup[1].colorLabel].cardIDs.map((id) => {
                            return (
                              <div className="cardWrapper" key={id}>
                                <Card size="extra-tiny" hand card={cards[id]} />
                              </div>
                            )
                          })
                        )}
                      </CardGroupContainer>
                    </CardSpot>
                  )}
                </Droppable>
              )}
            </CardSpotRow>
          )
        })}
      </CardSpotColumn>
      <ShowWildCanastaDialog
        selectedWildCanastaCardGroup={selectedWildCanastaCardGroup}
        setSelectedWildCanastaCardGroup={setSelectedWildCanastaCardGroup}
      />
    </Container>
  )
}

const Container = styled.div`
  border: 1px solid ${c.lightBackground};
  padding: 1rem;
  margin-top: -30px;
  width: 71vw;
  height: ${isTablet ? '56vh' : '60vh'};
  border-radius: 10px;
  background-color: ${c.darkBackground};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`

const CardSpotRow = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;

  justify-content: space-around;
`

const ShowCanastaButton: React.FC<{
  size?: 'small' | 'tiny'
  onClick: () => void
}> = ({ children, size, onClick }) => {
  return (
    <MyButton size={size} onClick={onClick} style={{ fontSize: '2vw' }}>
      {children}
    </MyButton>
  )
}

const ClosedIndicator = styled.div<{ includesWild?: boolean }>`
  color: ${({ includesWild }) => (includesWild ? 'black' : c.lightRed)};
  font-weight: bolder;
  font-size: 2vw;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ includesWild }) =>
    includesWild ? 'black' : c.lightRed};
  background-color: ${c.lightBackground};
  width: 6vw;
  border-radius: 20px;
  padding: 2px;
`

const CardSpotColumn = styled.div<{ middle?: boolean }>`
  width: ${({ middle }) => (middle ? '19vw' : '26vw')};
  display: flex;
  flex-direction: column;
`
