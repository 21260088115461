/* eslint-disable no-loop-func */
import * as d3 from 'd3-array'
import {
  DraggableStateSnapshot,
  DraggingStyle,
  DropAnimation,
  NotDraggingStyle
} from 'react-beautiful-dnd'
import { isMobileOnly } from 'react-device-detect'
import { Group, MyGameInterface, Player, TeamName } from './store/game'
import { store } from './store/index'
const {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals
} = require('unique-names-generator')

export const generateName = (num = 3): string => {
  return uniqueNamesGenerator({
    dictionaries: [adjectives, animals, colors],
    length: num
  })
}

export const checkClose = ({ group }: { group: Group }): boolean => {
  if (group.id === 'discard' || group.id.includes('hand')) return false
  return (
    (group.cardIDs.length >= 7 && !group.id.includes('Three')) ||
    (group.cardIDs.length >= 8 && group.id.includes('Three'))
  )
}

export const getPlayersFromMyContext = (): Player[] => {
  const { me, myGame } = store.getState().game
  const players = [...myGame.playersInRoom]
  if (me?.position === undefined) return players
  while (players[0].clientID !== me.clientID) {
    const last = players.pop()
    if (!last) console.error('cannot unshift anymore', players)
    last && players.unshift(last)
  }
  return players
}

const determineCountNegative = (
  groups: {
    [index: string]: Group
  },
  teamName: TeamName
): boolean => {
  for (let key in groups) {
    if (key.includes(teamName)) {
      if (groups[key].cardIDs.length >= 7) {
        return false
      }
    }
  }
  return true
}

export const calcHandsAndTable = (teamName: TeamName) => {
  const { groups, playersInRoom } = store.getState().game.myGame
  const { changeScores } = store.getActions().game
  let teamClientIDs = playersInRoom
    .filter((p) => p.team === teamName)
    .map((p) => p.clientID)

  let table = 0
  let hands = 0

  for (let key in groups) {
    if (key.includes(teamName)) {
      table += assignPoints(groups[key].cardIDs)
    } else if (
      key.includes(teamClientIDs[0]) ||
      key.includes(teamClientIDs[1])
    ) {
      hands += assignPoints(groups[key].cardIDs)
    }
  }

  const countNegative = determineCountNegative(groups, teamName)
  if (countNegative) table = 0 - table
  changeScores({ score: table - hands, team: teamName })
}

const assignPoints = (ids: string[]): number => {
  return ids.reduce((accum, id) => {
    if (/Joker/g.test(id)) return accum + 50
    else if (/Ace|Two/g.test(id)) return accum + 20
    else if (/Eight|Nine|Ten|Jack|Queen|King/g.test(id)) return accum + 10
    else if (/Four|Five|Six|Seven/g.test(id)) return accum + 5
    else return accum
  }, 0)
}

export const resetCardGroups = (myGame: MyGameInterface): void => {
  let groups = myGame.groups
  let playersInRoom = myGame.playersInRoom

  //creating deck
  let allIds: string[] = []
  Object.keys(groups).forEach((key, idx) => {
    allIds.push(...groups[key].cardIDs)
    groups[key].cardIDs.length = 0
  })

  //set new starting player, if the final player started last game, first player start next game
  //increment number of games played
  myGame.numberOfGamesPlayed++
  myGame.activePlayer =
    myGame.playersInRoom[
      myGame.numberOfGamesPlayed % myGame.playersInRoom.length
    ]

  console.log(
    '>>>>>: myGame.numberOfGamesPlayed % myGame.playersInRoom.length',
    myGame.numberOfGamesPlayed % myGame.playersInRoom.length
  )

  console.log('active player position', myGame?.activePlayer?.position)

  for (let i = 0; i < 20; i++) {
    d3.shuffle(allIds)
  }

  groups['deck'].cardIDs = allIds

  //dealing
  playersInRoom.forEach((player) => {
    for (let i = 0; i < 13; i++) {
      let lastCard = groups['deck'].cardIDs.pop()
      lastCard && groups[`hand-${player.clientID}`].cardIDs.push(lastCard)
    }
  })
}

export const getMobileOrientation = (): 'portrait' | 'landscape' | '' => {
  if (!isMobileOnly) return ''
  let orientation: 'portrait' | 'landscape' = 'landscape'
  if (window.matchMedia('(orientation: portrait)').matches)
    orientation = 'portrait'
  return orientation
}

export const checkGoneDownAndAlterGameState = (
  newGameState: MyGameInterface,
  hasPickedPack: boolean
): boolean => {
  const { myGame, me } = store.getState().game
  const myHandIDs = newGameState.groups[`hand-${me.clientID}`].cardIDs

  if (
    myGame.teamInfo[me.team as TeamName].goneDown ||
    !determineShouldGoDown(myGame.groups, me.team as TeamName) ||
    myGame.groups['deck'].cardIDs.length <= 8
  )
    return false
  else {
    newGameState.teamInfo[me.team as TeamName].goneDown = true
    if (hasPickedPack) {
      return false
    }
    const otherTeam = me.team === 'red' ? 'blue' : 'red'

    //if this is the first team to go down, draw 4, otherwise draw 3
    const deckIDs = myGame.groups['deck'].cardIDs
    if (!myGame.teamInfo[otherTeam as TeamName].goneDown) {
      const fourSplicedCardsFromDeck = deckIDs.splice(-4, 4)
      newGameState.groups[`hand-${me.clientID}`].cardIDs = [
        ...myHandIDs,
        ...fourSplicedCardsFromDeck
      ]
    } else {
      const threeSplicedCardsFromDeck = deckIDs.splice(-3, 3)
      newGameState.groups[`hand-${me.clientID}`].cardIDs = [
        ...myHandIDs,
        ...threeSplicedCardsFromDeck
      ]
    }
    return true
  }
}

const determineShouldGoDown = (
  groups: { [index: string]: Group },
  teamName: TeamName
): boolean => {
  let res = false
  for (let groupName in groups) {
    if (
      groupName.includes(teamName) &&
      !groupName.includes('Three') &&
      !groupName.includes('hand')
    ) {
      if (groups[groupName].cardIDs.length >= 3) res = true
    }
  }
  return res
}

export const arraysContainSameElements = (
  ar1: string[],
  ar2: string[]
): boolean => {
  if (ar1.length !== ar2.length) return false
  let ar1Copy = [...ar1]
  let ar2Copy = [...ar2]
  ar1Copy.sort()
  ar2Copy.sort()
  for (let i = 0; i < ar1Copy.length; i++) {
    if (ar1Copy[i] !== ar2Copy[i]) return false
  }
  return true
}

export const getDropAnimationStyle = (
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot
) => {
  if (!snapshot.isDropAnimating || snapshot.draggingOver?.includes('hand')) {
    return style
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation as DropAnimation
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`
  // add a bit of turn for fun

  // patching the existing style
  return {
    ...style,
    transform: `${translate}`,
    // slowing down the drop because we can
    //transition: `all ${curve} ${duration + 1}s`,
    transitionDuration: '0.0000001s'
  }
}

export const capitalCase = (str: string): string => {
  if (!str) return str
  let res = ''
  for (let i = 0; i < str.length; i++) {
    let char = str[i]
    if (i === 0) {
      res += char.toUpperCase()
    } else if (char.match(/[A-Z]/)) {
      res += ` ${char}`
    } else {
      res += char
    }
  }
  return res
}
