import styled from '@emotion/styled'
import isEmpty from 'lodash.isempty'
import React, { ReactNode } from 'react'
import { colors as c } from '../colors'
import { CardInterface } from '../store/game'
import { Symbol } from './Symbol'

interface P {
  card: CardInterface
  sideways?: boolean
  style?: React.CSSProperties
  size?: 'tiny' | 'extra-tiny' | 'small' | 'medium' | 'large'
  hand?: boolean
  idx?: number
}

export const Card: React.FC<P> = ({ card, sideways, ...other }) => {
  if (!card?.id || isEmpty(card))
    return (
      <CardContainer
        style={{ ...other.style, backgroundColor: c.darkBackground }}
      />
    )

  return (
    <CardContainer {...other} onClick={(e) => e.preventDefault()}>
      <DivC info size={other.size}>
        {card.rank.shortName !== 'Joker' ? (
          <CardText name={card.rank.shortName}>{card.rank.shortName}</CardText>
        ) : (
          <div
            style={{ fontSize: '60%', fontFamily: 'Dancing Script, cursive' }}
          >
            Jkr
          </div>
        )}
      </DivC>

      <DivC />
      <DivC />
      <DivC />
      <CardCenter name={card.rank.shortName} size={other.size}>
        {card.rank.shortName !== 'Joker' ? (
          <Symbol size="100%" suit={card.suit.name} />
        ) : (
          <Symbol size="100%" suit={'none'} />
        )}
      </CardCenter>
      <DivC />
      <DivC />
      <DivC />

      <DivC size={other.size} info>
        {card.rank.shortName !== 'Joker' ? (
          <CardText name={card.rank.shortName}>{card.rank.shortName}</CardText>
        ) : (
          <div
            style={{ fontSize: '60%', fontFamily: 'Dancing Script, cursive' }}
          >
            Jkr
          </div>
        )}
      </DivC>
    </CardContainer>
  )
}

export const CardBack: React.FC<{
  lastCard?: boolean
  numberOfCards?: number
  empty?: boolean
  hand?: boolean
  style?: React.CSSProperties
}> = ({
  empty = false,
  hand = false,
  style,
  lastCard = false,
  numberOfCards
}) => {
  if (empty)
    return (
      <CardContainer style={{ ...style, backgroundColor: c.darkBackground }} />
    )
  if (lastCard) {
    return (
      <CardContainer back style={{ ...style, display: 'block' }} hand={hand}>
        <LastCardWithNumber>{numberOfCards}</LastCardWithNumber>
      </CardContainer>
    )
  }
  return (
    <CardContainer back style={style} hand={hand}>
      <DivC>
        <Symbol />
      </DivC>
      <DivC>
        <Symbol />
      </DivC>
      <DivC>
        <Symbol />
      </DivC>
      <DivC>
        <Symbol />
      </DivC>
      <DivC>
        <Symbol />
      </DivC>
      <DivC>
        <Symbol />
      </DivC>
      <DivC>
        <Symbol />
      </DivC>
      <DivC>
        <Symbol />
      </DivC>
      <DivC>
        <Symbol />
      </DivC>
    </CardContainer>
  )
}

const LastCardWithNumber = styled.div`
  color: ${c.lightBackground};
  font-size: 100%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const CardText = styled.div<{ name?: string }>`
  font-size: ${({ name }) => (name === '10' ? '75%' : '100%')};
  font-weight: bolder;
  font-family: 'Dancing Script', cursive;
`

interface DivCProps {
  children?: ReactNode
  upsideDown?: boolean | undefined
  info?: boolean
  size?: 'tiny' | 'extra-tiny' | 'small' | 'medium' | 'large'
}

const DivC = styled.div<DivCProps>`
  border: ${({ children }) => {
    if (!children) return `1px solid ${c.lightBackground}`
  }};
  transform: ${({ upsideDown }) => (upsideDown ? `rotate(-180deg)` : null)};
  text-align: center;
  font-size: ${({ size }) => (size === 'extra-tiny' ? '60%' : '110%')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;
`

const CardCenter = styled(DivC)<{ name?: string }>`
  margin: ${({ name }) => (name === '10' ? '-4px' : '-3px')};
`

const CardContainer = styled.div<{
  back?: boolean
  size?: 'tiny' | 'extra-tiny' | 'small' | 'medium' | 'large'
  hand?: boolean
  almostClosed?: boolean
}>`
  border: ${({ back }) =>
    back ? `1px solid ${c.dark}` : `1px solid ${c.lightRed}`};
  border-radius: 10px;
  margin: ${({ hand }) => !hand && '1rem 0.5rem'};
  width: ${({ size }) =>
    size === 'tiny' ? '10vmin' : size === 'extra-tiny' ? '4vmin' : '18vw'};
  height: ${({ size }) =>
    size === 'tiny' ? '13vmin' : size === 'extra-tiny' ? '6.5vmin' : '27vw'};
  min-height: 30px;
  min-width: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1r 1fr;
  justify-items: center;
  align-items: center;
  color: ${c.darkBlue};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: ${({ back }) => (back ? c.lightRed : c.lightBackground)};
  &:hover {
    transform: ${({ size }) =>
      size !== 'extra-tiny' ? 'scale(1.02)' : 'scale(1.05)'};
  }
`
