import styled from '@emotion/styled'
import React from 'react'
import { colors } from '../colors'
import { ClickableAnchor } from './ClickableAnchor'
const bmc = require('../images/bmc-black.png')

export const BuyMeACoffeeButton: React.FC = () => {
  let ref = React.useRef<HTMLAnchorElement>(null)

  return (
    <ClickableAnchor
      style={{
        margin: 5,
        justifyContent: 'center',
        backgroundColor: colors.lightRed,
        borderRadius: 15,
        paddingLeft: 15,
        paddingRight: 15,
        color: 'white'
      }}
      ref={ref}
      href="https://www.buymeacoffee.com/canasta.io/e/19648"
      target="_blank"
    >
      <img
        src={'bmc-black.png'}
        alt="Buy Me A Coffee"
        style={{ height: 30, paddingLeft: 2, paddingRight: 2 }}
      />
      <DynamicSpan>{'Support Us :)'}</DynamicSpan>
    </ClickableAnchor>
  )
}

const DynamicSpan = styled.span`
  font-size: 25px;
  font-family: 'Cookie';
  padding-left: 4px;
  padding-right: 2px;
  white-space: nowrap;
  @media (max-width: 800px) {
    font-size: 3vw;
    padding: 0px;
    margin: 0px;
  }
`
