export const colors = {
  extraLightRed: '#EA6564',
  lightRed: 'rgb(238,106,101)',
  darkRed: '#811111',
  lightBlue: '#ADD8E6',
  darkBlue: '#004369',
  dark: '#2F2440',
  darkBackground: '#282c34',
  darkBackgroundTranslucent: ' rgba(0,0,0,0.9)',
  lightBackground: '#fffff2',
  gold: 'gold',
  lightGreen: 'lightgreen',
  gray: 'gray'
}
