import styled from '@emotion/styled'
import arrayMove from 'array-move'
import React, { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { GrClipboard } from 'react-icons/gr'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Accentuated } from '../1-reusable/Accentuated'
import { Column, MediaRow } from '../1-reusable/ColumnAndRow'
import { Header } from '../1-reusable/Header'
import { MyButton } from '../1-reusable/MyButton'
import { NormalFont } from '../1-reusable/NormalFont'
import {
  joinGame,
  populateInitialHistory,
  receiveInitialGameState,
  refreshSocketAndStore,
  stopListeningForEvent
} from '../api'
import { colors as c } from '../colors'
import { typedActions, typedState } from '../store'
import { MyGameInterface } from '../store/game'

export const Lobby: React.FC<RouteComponentProps<
  { room: string },
  any,
  { fromJoinGame: boolean }
>> = ({ match, location }) => {
  const [alert, setAlert] = useState({ message: '', color: '' })
  const history = useHistory()
  const { me, myGame } = typedState((state) => state.game)
  const { room } = typedState((state) => state.info)
  const playersInRoom = myGame.playersInRoom
  const { deal, updatePlayersInRoom } = typedActions((actions) => actions.game)

  useEffect(() => {
    receiveInitialGameState()
  }, [])

  useEffect(() => {
    if (myGame.status === 'started') {
      const path = `/game/${match.params.room}/${me.clientID}`
      window.localStorage.setItem('lastGamePath', path)
      history.push(path)
    }
  }, [myGame.status])

  useEffect(() => {
    if (
      myGame.playersInRoom[0]?.clientID === me.clientID &&
      myGame.playersInRoom.length <= 1 &&
      location?.state?.fromJoinGame
    ) {
      setAlert({
        message:
          'This game is empty.  Are you sure you entered the correct code?',
        color: c.lightRed
      })
    } else {
      setAlert({ message: '', color: '' })
    }
  }, [location?.state?.fromJoinGame, me.clientID, myGame.playersInRoom])

  useEffect(() => {
    me.name && !room && joinGame(match.params.room, me.name)
  }, [me.name])

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result

    if (!destination) return
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    let newPlayersInRoom = arrayMove(
      playersInRoom,
      source.index,
      destination.index
    )
    newPlayersInRoom = newPlayersInRoom.map((player, idx) => {
      return { ...player, position: idx, team: idx % 2 ? 'blue' : 'red' }
    })
    updatePlayersInRoom(newPlayersInRoom)
    const newGameState: MyGameInterface = {
      ...myGame,
      playersInRoom: newPlayersInRoom,
      activePlayer: newPlayersInRoom[0]
    }
    populateInitialHistory({ gameState: newGameState })
  }

  if (!me.name)
    return (
      <div>
        <Header
          onClick={() => {
            refreshSocketAndStore()
            history.push('/')
          }}
        >
          Canasta
        </Header>
        <MyButton
          onClick={() => {
            refreshSocketAndStore()
            history.push('/')
          }}
        >
          Home
        </MyButton>
      </div>
    )
  return (
    <Container>
      <MyButton
        style={{
          position: 'absolute',
          top: '5px',
          left: '5px'
        }}
        size="small"
        onClick={() => {
          refreshSocketAndStore()
          history.push('/')
        }}
      >
        &#8592; Home
      </MyButton>
      <Header>Canasta</Header>
      <Column
        style={{
          alignSelf: 'center'
        }}
      >
        <NormalFont
          style={{
            fontWeight: 'lighter',
            fontSize: 18,
            lineHeight: 1.5,
            textAlign: 'left'
          }}
        >
          Please wait for all members to join before pressing the{' '}
          <Accentuated>Start Game</Accentuated> button
        </NormalFont>
      </Column>
      <MediaRow
        style={{
          justifyContent: 'space-between',
          alignItems: 'stretch',
          minWidth: '50vw',
          minHeight: '30vh'
        }}
      >
        <Column
          style={{ justifyContent: 'space-between', alignItems: 'stretch' }}
        >
          <NormalFont style={{ marginTop: '10px' }}>
            Send this code to your friends:{' '}
          </NormalFont>
          <NormalFont style={{ color: c.lightRed }}>
            {match.params.room}
          </NormalFont>
          <RowContainer style={{ justifyContent: 'space-around' }}>
            <CopyToClipboard
              text={match.params.room}
              onCopy={() => {
                setAlert({ message: 'Link copied!', color: c.lightGreen })
                setTimeout(() => setAlert({ message: '', color: '' }), 10000)
              }}
            >
              <MyButton size="small">
                <PaddedIcon /> Copy Code{' '}
              </MyButton>
            </CopyToClipboard>
            <MyButton
              size="small"
              onClick={() => {
                stopListeningForEvent('joined')
                stopListeningForEvent('assigned-clientID')
                populateInitialHistory()
                if (myGame.status === 'waiting') {
                  deal()
                  populateInitialHistory({ status: 'started' })
                }
              }}
            >
              Start Game
            </MyButton>
          </RowContainer>
        </Column>
        <Column
          style={{
            alignItems: 'stretch',
            padding: '10px'
          }}
        >
          <NormalFont style={{ paddingBottom: '5px' }}>
            Drag To Order Turns:
          </NormalFont>
          <Column
            style={{
              border: `3px solid ${c.lightBackground}`,
              borderRadius: '15px',
              padding: '10px 10px',
              alignItems: 'stretch',
              justifyContent: 'flex-start',
              flex: 1
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={'turn-order-picker'}>
                {(provided) => {
                  return (
                    <PlayersContainer
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {playersInRoom.map((player, idx) => {
                        return (
                          <Draggable
                            key={player.clientID}
                            draggableId={player.clientID}
                            index={idx}
                          >
                            {(provided) => {
                              return (
                                <div
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <div
                                    style={{
                                      borderRadius: 10,
                                      paddingRight: 10,
                                      paddingLeft: 10
                                    }}
                                  >
                                    <NormalFont
                                      color={idx % 2 ? c.lightBlue : c.lightRed}
                                    >
                                      <span
                                        style={{ color: c.lightBackground }}
                                      >
                                        {idx + 1}:{' '}
                                      </span>
                                      {player.name}
                                    </NormalFont>
                                  </div>
                                </div>
                              )
                            }}
                          </Draggable>
                        )
                      })}
                    </PlayersContainer>
                  )
                }}
              </Droppable>
            </DragDropContext>
          </Column>
        </Column>
      </MediaRow>
      <NormalFont style={{ color: alert.color }}>{alert.message}</NormalFont>
    </Container>
  )
}

const PaddedIcon = styled(GrClipboard)`
  color: ${c.dark};
  size: 3%;
  position: relative;
  top: 4px;
`

const Container = styled.div<{ isDraggingOver?: boolean }>`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  > * {
    margin: 1%;
  }
  background-color: ${({ isDraggingOver }) => isDraggingOver && 'black'};
`

const PlayersContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
`

const RowContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  > * {
    margin: 1%;
  }
`
