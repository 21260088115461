import styled from '@emotion/styled'
import React from 'react'
import { DebugStatus, sendDebugEmail } from '../api'
import { Column, Row } from './ColumnAndRow'
import { MyButton } from './MyButton'
import { SendButtonLoader } from './SendButtonLoader'
import { IoMdSend } from 'react-icons/io'
import { colors } from '../colors'
import { toolButtonStyle } from '../reusable-styles'
import html2canvas from 'html2canvas'
import throttle from 'lodash.throttle'

export type Animating = 'expand' | 'contract' | ''

export const ExpandableButton: React.FC<{}> = ({}) => {
  const [animating, setAnimating] = React.useState<Animating>('')
  const [debugStatus, setDebugStatus] = React.useState<DebugStatus>('')
  const [text, setText] = React.useState('')

  const reportBug = throttle(async () => {
    window.scrollTo(0, 0)
    const game = document.getElementById('game-container')
    const toolbox = document.getElementById('toolbox')
    if (game && toolbox) {
      toolbox.style.visibility = 'hidden'
      html2canvas(game, {
        foreignObjectRendering: true
      }).then((canvas) => {
        const image = canvas.toDataURL('image/png')
        sendDebugEmail(image, text, setDebugStatus)

        // //loading timeout
        // setTimeout(() => {
        //   if (debugStatus === 'loading') setDebugStatus('error')
        // }, 8000)
      })
      toolbox.style.visibility = 'visible'
    }
  }, 3000)
  return (
    <Column
      style={{
        alignItems: 'flex-start',
        backgroundColor: animating ? colors.darkBackground : undefined,
        border: animating ? '2px solid black' : undefined,
        borderRadius: 15
      }}
    >
      <SendButtonLoader
        title="Report Bug"
        status={debugStatus}
        onClick={async () => {
          if (animating === 'expand') setAnimating('contract')
          else setAnimating('expand')
        }}
        onSuccess={() => {
          setDebugStatus('')
          alert('bug was successfully reported')
        }}
        onError={() => {
          setDebugStatus('')
          alert('unable to report bug at this time')
        }}
      />
      {!!animating && (
        <AnimatedColumn
          animating={animating}
          onAnimationEnd={async () => {
            //after we contract, we close
            if (animating === 'expand')
              window.scrollTo(0, document.body.scrollHeight)
            else if (animating === 'contract') {
              setAnimating('')
              await reportBug()
            }
          }}
        >
          <div style={{ color: colors.gold }}>Describe the issue: </div>
          <ExpandableTextArea
            animating={animating}
            rows={5}
            cols={10}
            style={{ width: '80%', height: '60%' }}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Row style={{ width: '50%' }}>
            <MyButton
              size="tiny"
              style={{
                ...toolButtonStyle,
                backgroundColor: colors.darkBackground,
                color: colors.gold
              }}
              onClick={(e) => {
                setAnimating('contract')
              }}
            >
              Close
            </MyButton>
            <MyButton
              size="tiny"
              style={{
                ...toolButtonStyle,
                backgroundColor: colors.darkBackground,
                color: colors.gold
              }}
              onClick={(e) => {
                setAnimating('contract')
                setDebugStatus('loading')
              }}
            >
              Send
            </MyButton>
          </Row>
        </AnimatedColumn>
      )}
    </Column>
  )
}

const AnimatedColumn = styled(Column)<{ animating: Animating }>`
  @keyframes expand {
    0% {
      height: 0;
      width: 0;
      opacity: 0;
    }
    70% {
      height: 300px;
      width: 400px;
      opacity: 0;
    }
    100% {
      height: 300px;
      width: 400px;
      opacity: 1;
    }
  }
  @keyframes contract {
    0% {
      height: 300px;
      width: 400px;
      opacity: 1;
    }
    30% {
      height: 300px;
      width: 400px;
      opacity: 0;
    }
    100% {
      height: 0;
      width: 0;
      opacity: 0;
    }
  }
  animation: ${({ animating }) => animating + ' 1000ms forwards'};
`

const ExpandableTextArea = styled.textarea<{ animating: Animating }>`
  /* @keyframes expand {
    0% {
      height: 0;
      width: 0;
      opacity: 0;
    }
    100% {
      height: 200;
      width: 300px;
      opacity: 1;
    }
  }
  @keyframes contract {
    0% {
      height: 200px;
      width: 300px;
      opacity: 1;
    }
    100% {
      height: 0;
      width: 0;
      opacity: 0;
    }
  }
  animation: ${({ animating }) => animating + ' 1000ms forwards'}; */
`
