import React from 'react'
import './Button.css'

export default class Button extends React.Component<
  {
    name: string
    orange?: boolean
    wide?: boolean
    clickHandler: (name: string) => any
  },
  {}
> {
  constructor(props: any) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick() {
    this.props.clickHandler(this.props.name)
  }

  render() {
    const className = [
      'component-button',
      this.props.orange ? 'orange' : '',
      this.props.wide ? 'wide' : ''
    ]

    return (
      <div className={className.join(' ').trim()}>
        <button onClick={this.handleClick}>{this.props.name}</button>
      </div>
    )
  }
}
