import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { sendGameState } from '../api'
import { colors as c } from '../colors'
import { cardInterfaceMap } from '../store/cardInterfaceMap'
import { Player } from '../store/game'
import { typedActions, typedState } from '../store/index'
import { Card } from './Card'
import { Column } from './ColumnAndRow'
import { NormalFont } from './NormalFont'

export const ShowHandsDialog: React.FC<{}> = () => {
  const { showHandClientID, playersInRoom, groups } = typedState(
    (state) => state.game.myGame
  )
  const cards = cardInterfaceMap
  const setShowHandClientID = typedActions(
    (state) => state.game.setShowHandClientID
  )
  const [playerShowingHand, setPlayerShowingHand] = useState<
    Player | undefined
  >(undefined)

  useEffect(() => {
    const player = playersInRoom.find((p) => p.clientID === showHandClientID)
    setPlayerShowingHand(player)
  }, [showHandClientID])

  if (!playerShowingHand) return null
  return (
    <Container>
      <TopLeft
        onClick={() => {
          setShowHandClientID('')
          sendGameState({ endTurn: false })
        }}
      >
        <CloseButton />
      </TopLeft>
      <Column
        style={{
          marginTop: '30px',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <NormalFont
          color={playerShowingHand.team === 'blue' ? c.lightBlue : c.lightRed}
        >
          {`${playerShowingHand.name}'s hand`}
        </NormalFont>
        <HandContainer>
          {groups[`hand-${playerShowingHand.clientID}`].cardIDs.map(
            (id, idx) => {
              return (
                <CardWrapper key={idx}>
                  <Card size="tiny" card={cards[id]} hand />
                </CardWrapper>
              )
            }
          )}
        </HandContainer>
      </Column>
    </Container>
  )
}

const CloseButton = styled(MdClose)`
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    box-shadow: 0 0 10px white;
  }
  border-radius: 30px;
  padding: 5px;
`

const TopLeft = styled.div`
  position: fixed;
  z-index: 3;
  top: 5px;
  left: 5px;
  color: ${c.lightBackground};
`

const Container = styled.div`
  background-color: ${c.darkBackgroundTranslucent};
  border: 1px solid ${c.lightBackground};
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 95vw;
  max-height: 95vh;
`

const HandContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  max-width: 70vw;
  min-width: 400px;
  .cardWrapper:last-child {
    overflow: visible;
  }
  .cardWrapper:hover {
  }
  .cardWrapper {
  }
`

const CardWrapper = styled.div<{ isDragging?: boolean }>`
  padding-top: 8px;
  padding-bottom: 8px;
  overflow: ${({ isDragging }) => (isDragging ? 'visible' : 'hidden')};
  &:hover {
    padding-left: 2px;
    padding-right: 2px;
  }
  &:last-child {
    overflow: visible;
  }
`
