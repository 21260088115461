import * as d3 from 'd3-array'
import { action, Action, debug } from 'easy-peasy'
import { cardInterfaceMap } from './cardInterfaceMap'
const { decks } = require('cards')

export interface CardInterface {
  rank: { shortName: string; longName: string }
  suit: { name: 'spades' | 'hearts' | 'diamonds' | 'clubs' | 'none' }
  id: string
}

export interface MyGameInterface {
  playersInRoom: Player[]
  numberOfGamesPlayed: number
  activePlayer: Player
  showHandClientID: string
  status: GameStatus
  groups: GroupInterfaceMap
  teamInfo: Record<TeamName, TeamInfo>
}

export type TeamName = 'red' | 'blue'

export interface TeamInfo {
  score: number
  goneDown: boolean
}

export interface Group {
  id: string
  title: string
  cardIDs: string[]
}

export type CardInterfaceMap = { [index: string]: CardInterface }
export type GroupInterfaceMap = { [index: string]: Group }

export interface SortableCards {
  cards: CardInterfaceMap
  groups: GroupInterfaceMap
}

export interface Player {
  name: string
  clientID: string
  isMyTurn?: boolean
  position?: number
  room?: string
  team: TeamName | ''
}

export interface Me extends Player {
  hasDiscarded: boolean
}

export type GameStatus = 'waiting' | 'started' | 'finished'

export interface GameModel {
  me: Me
  setMe: Action<GameModel, { [key in keyof Me]?: any }>
  updatePlayersInRoom: Action<GameModel, Player[]>
  myGame: MyGameInterface
  setGameStatus: Action<GameModel, GameStatus>
  setShowHandClientID: Action<GameModel, string>
  setMyGroups: Action<GameModel, GroupInterfaceMap>
  setHandIDs: Action<GameModel, { handName: string; handIDs: string[] }>
  shuffle: Action<GameModel>
  deal: Action<GameModel>
  updateGameState: Action<GameModel, MyGameInterface>
  addHand: Action<GameModel, { name: string; clientID: string }>
  changeScores: Action<GameModel, { score: number; team: TeamName }>
  setGoneDown: Action<GameModel, { value: boolean; team: TeamName }>
}

const generateInitialCards = (numDecks: number): CardInterface[] => {
  let newDeck = new decks.StandardDeck({ jokers: 2 })
  let oneSet = newDeck.draw(54)
  let allCards: CardInterface[] = []
  for (let i = 0; i < numDecks; i++) {
    allCards.push(...oneSet)
  }
  return allCards
}

export const shuffleCardsIDs = (): string[] => {
  let ids = Object.keys(cardInterfaceMap)
  for (let i = 0; i < 20; i++) {
    d3.shuffle(ids)
  }
  return ids
}

export const initialGameState = (
  cardIDs?: string[],
  gameState?: MyGameInterface
): MyGameInterface => {
  cardIDs = cardIDs || shuffleCardsIDs()

  let myGame: MyGameInterface = gameState || {
    teamInfo: {
      blue: { score: 0, goneDown: false },
      red: { score: 0, goneDown: false }
    },
    playersInRoom: [],
    numberOfGamesPlayed: 0,
    activePlayer: { name: '', clientID: '', position: 0, team: '' },
    status: 'waiting',
    showHandClientID: '',

    //deck starts with all the cardIDs
    groups: {
      deck: { id: 'deck', title: 'Deck', cardIDs },
      discard: { id: 'discard', title: 'Discard', cardIDs: [] },
      redWild: { id: 'redWild', title: 'Red Wild', cardIDs: [] },
      redThree: { id: 'redThree', title: 'Red Three', cardIDs: [] },
      redFour: { id: 'redFour', title: 'Red Four', cardIDs: [] },
      redFive: { id: 'redFive', title: 'Red Five', cardIDs: [] },
      redSix: { id: 'redSix', title: 'Red Six', cardIDs: [] },
      redSeven: { id: 'redSeven', title: 'Red Seven', cardIDs: [] },
      redEight: { id: 'redEight', title: 'Red Eight', cardIDs: [] },
      redNine: { id: 'redNine', title: 'Red Nine', cardIDs: [] },
      redTen: { id: 'redTen', title: 'Red Ten', cardIDs: [] },
      redJack: { id: 'redJack', title: 'Red Jack', cardIDs: [] },
      redQueen: { id: 'redQueen', title: 'Red Queen', cardIDs: [] },
      redKing: { id: 'redKing', title: 'Red King', cardIDs: [] },
      redAce: { id: 'redAce', title: 'Red Ace', cardIDs: [] },
      blueWild: { id: 'blueWild', title: 'Blue Wild', cardIDs: [] },
      blueThree: { id: 'blueThree', title: 'Blue Three', cardIDs: [] },
      blueFour: { id: 'blueFour', title: 'Blue Four', cardIDs: [] },
      blueFive: { id: 'blueFive', title: 'Blue Five', cardIDs: [] },
      blueSix: { id: 'blueSix', title: 'Blue Six', cardIDs: [] },
      blueSeven: { id: 'blueSeven', title: 'Blue Seven', cardIDs: [] },
      blueEight: { id: 'blueEight', title: 'Blue Eight', cardIDs: [] },
      blueNine: { id: 'blueNine', title: 'Blue Nine', cardIDs: [] },
      blueTen: { id: 'blueTen', title: 'Blue Ten', cardIDs: [] },
      blueJack: { id: 'blueJack', title: 'Blue Jack', cardIDs: [] },
      blueQueen: { id: 'blueQueen', title: 'Blue Queen', cardIDs: [] },
      blueKing: { id: 'blueKing', title: 'Blue King', cardIDs: [] },
      blueAce: { id: 'blueAce', title: 'Blue Ace', cardIDs: [] }
    }
  }

  return myGame
}

export const initialMe: Me = {
  name: '',
  position: 0,
  clientID: '',
  team: '',
  hasDiscarded: false
}

export const gameModel: GameModel = {
  me: initialMe,
  setMe: action((state, obj) => {
    state.me = { ...state.me, ...obj }
  }),
  myGame: initialGameState(),
  setGameStatus: action((state, status) => {
    state.myGame.status = status
  }),
  setShowHandClientID: action((state, clientID) => {
    state.myGame.showHandClientID = clientID
  }),
  setMyGroups: action((state, groups) => {
    state.myGame = { ...state.myGame, groups }
  }),
  setHandIDs: action((state, data) => {
    state.myGame.groups[data.handName].cardIDs = data.handIDs
  }),
  shuffle: action((state, payload) => {
    let ids = shuffleCardsIDs()
    state.myGame = initialGameState(ids)
  }),
  deal: action((state) => {
    let pos = 0

    for (let i = 0; i < state.myGame.playersInRoom.length * 13; i++) {
      let lastCardID = debug(state.myGame.groups['deck'].cardIDs.pop())
      if (lastCardID) {
        state.myGame.groups[
          `hand-${state.myGame.playersInRoom[pos].clientID}`
        ].cardIDs.push(lastCardID)
      }

      if (pos === state.myGame.playersInRoom.length - 1) pos = 0
      else pos++
    }
  }),
  updatePlayersInRoom: action((state, players) => {
    state.myGame.playersInRoom = players

    //set active player to last player that joined
    //TODO make this choosable
    state.myGame.activePlayer = state.myGame.playersInRoom[0]
  }),
  updateGameState: action((state, gameState) => {
    state.myGame = gameState
  }),
  addHand: action((state, { name, clientID }) => {
    state.myGame.groups[`hand-${clientID}`] = {
      id: `hand-${clientID}`,
      title: `${name}'s Hand`,
      cardIDs: []
    }
  }),
  changeScores: action((state, { score, team }) => {
    state.myGame.teamInfo[team].score += score
  }),
  setGoneDown: action((state, { value, team }) => {
    state.myGame.teamInfo[team].goneDown = value
  })
}

// dealHands: action((state, payload) => {
//   state.hands.forEach((hand, idx) => {
//     let fiveCards = [] as CardInterface[]
//     for (let i = 0; i < 13; i++) {
//       let card = state.cards.pop()
//       card && fiveCards.push(card)
//     }
//     state.hands[idx] = { ...state.hands[idx], cards: fiveCards }
//   })
// })
