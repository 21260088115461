import styled from '@emotion/styled'
import { colors as c } from '../colors'

export const NormalFont = styled.div<{ color?: string }>`
  font-family: Helvetica, sans-serif;
  font-size: 20;
  color: ${({ color }) => color && color};
  display: block;
  white-space: wrap;
  &::before {
    content: '\n\n';
  }
`

export const NormalFontH2Spaced = styled.h2<{ color?: string }>`
  font-family: Helvetica, sans-serif;
  font-size: 25px;
  color: ${c.lightRed};
  text-align: left;
  font-weight: bolder;
  display: block;
  white-space: wrap;
  &::before {
    content: '\n\n';
  }
`

export const NormalParagraphFontSpaced = styled.div`
  font-size: 20px;
  font-weight: normal;
  color: white;
  line-height: 1.5;
  font-family: Helvetica, sans-serif;
  text-align: justify;
  &::before {
    content: '\n\n';
  }
`

export const NormalListItemFontSpaced = styled.li`
  font-size: 20px;
  font-weight: normal;
  color: white;
  line-height: 1.5;
  font-family: Helvetica, sans-serif;
  text-align: justify;
`

export const UnorderedListContainer = styled.ul``
